// import {Howl} from 'howler';

class MusicPlayer {
  constructor() {
    if (MusicPlayer.instance == null) { 
      this.sound_files = {
        theme : new Audio('sound-music/theme.mp3')
      };
      try {
        this.sound_files.theme.loop = true;
        this.sound_files.theme.autoplay = true;
      } catch(err) {}

      MusicPlayer.instance = this;
    }
    
    return MusicPlayer.instance;
  }

  /**
   * Plays the filename from SFX folder without .mp3 extension
   * 
   * @param {string} soundName 
   */
  play(soundName) {
    new Promise ( () => this.sound_files[soundName].play() )
      .then(() => console.log("autoplay true"))
      .catch(() => console.log("no autoplay"))
  }

  /**
   * Stops the filename from SFX folder without .mp3 extension
   * 
   * @param {string} soundName 
   */
  stop(soundName) {
    this.sound_files[soundName].pause();
  }

  /**
   * Sets the volume for given sound
   * 
   * @param {string} soundName 
   * @param {number} level 
   */
  volume(soundName, level) {
    this.sound_files[soundName].volume = level;
  }

  /**
   * Sets the volume for given sound
   * 
   * @param {string} soundName 
   * @param {boolean} level 
   */
   loop(soundName, val) {
    this.sound_files[soundName].loop(val);
  }

  state(soundName) {
    return this.sound_files[soundName].state();
  }

  /**
   * Fades sound, from values (0-1 or decimal), to alues (0-1 or decimal), duration miliseconds
   * 
   * @param {string} soundName 
   * @param {number} from 
   * @param {number} to 
   * @param {number} duration 
   */
  fade(soundName, from, to, duration) {
    this.sound_files[soundName].fade(from, to, duration);
  }

  autoPlay(soundName, val) {
    this.sound_files[soundName].on('load',this.play(soundName));
  }

}

const musicPlayer = new MusicPlayer();
export default musicPlayer;
