import getUrlParam from '../UrlParams/urlParams';

class MultiLanguage {

  constructor(defaultLang) {
    this.currentLanguage = defaultLang;
    this.get = {};
    this.update = function() {
      document.querySelectorAll("[data-langname]").forEach((el) => {
        let langName = el.getAttribute('data-langname');
        let testdataTip = el.getAttribute('data-tip');
        if(testdataTip) {
          // console.log('....TEST ')
          el.setAttribute('data-tip', this.get[langName]);
        } else {
          el.innerHTML = this.get[langName];
        }
      });
    }
  }

  t = function(wordId) {
    if(typeof wordId === 'undefined') {
      console.error('MultiLanguage error in method label got undefined argument wordId ' +
        'please add argument wordId in your label method call => ')
      return ' - ';
    } else if(typeof this.get[wordId] !== 'undefined') {
      return this.get[wordId];
    }
    return ' - ';
  }

  loadLangPack = function(currentPack, onLanguageLoad) {
    var classReference = this;
    var currentPack_ = null;
    if(typeof currentPack === 'undefined') {
      currentPack_ = this.currentLanguage;
    } else {
      currentPack_ = currentPack;
    }

    var pPath = "/multiLang/" + currentPack_ + ".json";
    fetch(pPath, {
      headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
    }).then((response) => {
      return response.json();
    }).then((res) => {
      classReference.get = res;
      classReference.update();
      onLanguageLoad();
    }).catch((err) => {
      console.warn("Error occured : ", err);
      // Like init procd
      var paramLang = getUrlParam('lang');
      if(paramLang === null) {
        paramLang = getUrlParam('language');
        if(paramLang === null) {
          paramLang = 'en';
        }
      }
      if(paramLang === 'rs') {paramLang = 'sr';}

      if (err.stack.indexOf("SyntaxError: Unexpected token '<'") !== -1) {
        // 'https://cdn.fastballs.elbetdev.com/?companyId=94&uid=nidza1234&lang=MARE&currency=rsd'
        paramLang = 'en';
        sessionStorage.setItem('LANG_URL_PARAM_IS_ILEGAL', true)
      }

      fetch("/multiLang/" + paramLang + ".json", {
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
      }).then((response) => {
        return response.json();
      }).then((res) => {
        classReference.get = res;
        classReference.update()
        onLanguageLoad()
      })
    })
  }
}

var paramLang = getUrlParam('lang');
if(paramLang === null) {
  paramLang = getUrlParam('language');
  if(paramLang === null) {
    paramLang = 'en';
  }
}

if(paramLang === 'rs') {paramLang = 'sr';}

let label = new MultiLanguage(paramLang);

if(typeof paramLang !== 'undefined' && paramLang !== null) {
  label.loadLangPack(paramLang, function() {
    // Loaded
  });
} else {
  label.loadLangPack('en', function() {
    label.update();
  });
}

export default label;
