import React from "react";
import NewMsgNotify from "../Chat/NewMsgNotify";
import BetsIconSvg from "../svg/bets-icon-svg";
import ChatIconSvg from "../svg/chat-icon-svg";
import ResultsIconSvg from "../svg/results-icon-svg";
import label from "../../services/Translation/lang";

export default function BottomNavigator(props) {
  return (
    <div className="bottom-navigator backdrop-blur">
      <div className="bottom-navigator-link" onClick={() => props.setActiveTab("bets")}>
        <BetsIconSvg />
        {label.t("bets")}
      </div>
      <div id="bottom-nav-results" className="bottom-navigator-link" onClick={() => props.setActiveTab("results")}>
        <ResultsIconSvg />
        Results
      </div>
      <div style={( sessionStorage.getItem('isChatVisible') === "false" || sessionStorage.getItem('isChatVisible') === false ? {display: 'none'} : {} )} className="bottom-navigator-link" onClick={() => {
          props.net.updateNewMsg = 0;
          props.setActiveTab("chat");
        }
      }>
        <NewMsgNotify className="chat-badge-bottom-navigator" hide={(props.net.updateNewMsg === 0 ? true : false)} label={props.net.updateNewMsg} updateNewMsg={props.net.updateNewMsg} />
        <ChatIconSvg />
        Chat
      </div>
    </div>
  );
}
