import ResultsBoxMyBets from "../Results/ResultsBoxMyBets";
import FadeIn from "react-fade-in";

function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;    
}

export default function MyBets(props) {
  return (
    <div className="results-tab">
      <FadeIn className="my-results-reversed">
        {props.myBetsItems.map((result, index) => {
          // console.log(result)
          try {
            var t = convertUTCDateToLocalDate(new Date(result.created_time.replace(' ', 'T')))
            // console.log('convert  t :  ',  t.toLocaleDateString())
            var nl = t.toLocaleTimeString()
            nl = nl.split(' ')[0]
            var N = t.toISOString()
            var date = N.split("T")[0];
            date = date.replace('-', '.')
            date = date.replace('-', '.')
            var dateY = date.split(".")[0]
            var dateM = date.split(".")[1]
            var dateD = date.split(".")[2]
            var constructMe = dateD + '.' + dateM + '.' + dateY;
          } catch(err) {}

          return (
            <ResultsBoxMyBets
              withoutHeader
              indexArray={props.indexArray}
              indexArrayQuota={props.indexArrayQuota}
              key={index}
              balls={result.numbers}
              date={constructMe}
              time={nl}
              roundNumber={result.round_id}
              stake={result.bet}
              baseStake={result.bet_eur}
              win={result.win}
              currency={result.currency_code}
              bonusBals={result.bonusBals}
              userCurrencyCode={props.userCurrencyCode}
              activeTicketEmitedWin={props.activeTicketEmitedWin}
            />
          );
        })}
      </FadeIn>
    </div>
  );
}
