import { useEffect, useMemo, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import drumMiddle from "../../assets/images/drum-middle.png";
import arrowDrum from "../../assets/images/arrow-test.png";
import lotteryDrumBackground from "../../assets/images/lottery-drum-background.png";
import LotteryBall from "./LotteryBall";
import oddHolder from "../../model/odd-holder";
import storage from "../../services/Storage/Storage";
import { isMobile, isExtraSmallResolution } from "../../services/Helpers/checkPlatform";

// import soundEffect from "../../services/Sounds/SoundFxPlayer";

export default function LotteryDrum(props) {
  const [arrowTimer, setArrowTimer] = useState(35);
  const [drumCounter, setDrumCounter] = useState(0);
  const [arrowDeg, setArrowDeg] = useState(0);

  const [localDrawnNumbers, setLocalDrawnNumbers] = useState(Array(35));
  const [currentBigNumber, setCurrentBigNumber] = useState(0);
  const [currentBigNumberOdd, setCurrentBigNumberOdd] = useState(0);
  const [checker, setChecker] = useState(0);

  const [drumX, setDrumX] = useState(272);
  const [drumY, setDrumY] = useState(272);

  useEffect(() => {
    if (isExtraSmallResolution() === true) {
      setDrumX(236); setDrumY(236);
    } else {
      setDrumX(272); setDrumY(272);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    if (props.gameStatus === 0) {
      setChecker(0);
      setLocalDrawnNumbers(Array(35));
      setArrowDeg(0);
      setDrumCounter(0);
      setArrowDeg(0);
      setArrowTimer(35);

      // let interval = setInterval(() => {
      //   if (props.percentage > 0) {
      //     console.log('TEST 1212')
      //     props.setPercentage(props.percentage - 1);
      //     setBounce(true);
      //     // countdown
      //     soundEffect.play('countdown');
      //     setTimeout(function () {
      //       setBounce(false);
      //     }, 500);
      //   } else {
      //     console.log('NULLL A ')
      //     soundEffect.play('countdown');
      //     props.setPercentage(0);
      //     // props.setPercentage(parseInt(props.nextRs));
      //     clearInterval(interval);
      //   }
      // }, 1000);
      // return () => clearInterval(interval);
    }
  }, [props, props.gameStatus, props.percentage]);


  useEffect(() => {
    if (props.gameStatus === 1) {
      if (props.mustBeDraw !== 0 && checker === 0) {
        setChecker(1);
        props.setBallInterval(1);
      } else if (checker === 1) {
        if (props.mustBeDraw < drumCounter && checker === 1) {
          setChecker(2);
          var timeDelta = parseFloat(storage.get("animation_time")) * 0.8;
          props.setBallInterval(timeDelta);
        }
      }
    }
  },[checker, drumCounter, props])

  useEffect(() => {
    if (props.gameStatus === 1) {
      // Normal flow
      let interval = setTimeout(() => {
        if (arrowTimer > 0 && drumCounter < 35) {
          let currentDrawnNumberEvent = new CustomEvent(
            "onCurrentDrawnNumber",
            {
              detail: {
                number: parseInt(props.drawnNumbers[drumCounter]),
                numberIndex: drumCounter,
                quota: oddHolder[drumCounter].odd
              },
            }
          );
          dispatchEvent(currentDrawnNumberEvent);
          setCurrentBigNumber(parseInt(props.drawnNumbers[drumCounter]));
          setCurrentBigNumberOdd(oddHolder[drumCounter].odd);
          localDrawnNumbers[drumCounter] = parseInt(
            props.drawnNumbers[drumCounter]
          );
          setDrumCounter(drumCounter + 1);
          if (arrowTimer > 1) {
            setArrowTimer(arrowTimer - 1);

            // props.setBounce(true);
            // setTimeout(function () {
            //   props.setBounce(false);
            // }, props.ballInterval);

            if (arrowTimer < 35) {
              setArrowDeg(arrowDeg + 10.28571);
            }
          } else {
            if (drumCounter === 34) {
              setArrowDeg(arrowDeg + 10.28571);
              // setBounce(true);
              // setTimeout(function () {
              //   setBounce(false);
              // }, props.ballInterval);
            }
          }
        }
      }, props.ballInterval);
      return () => clearInterval(interval);
    }
  }, [
    props.ballInterval,
    arrowTimer,
    arrowDeg,
    props.gameStatus,
    props.drawnNumbers,
    localDrawnNumbers,
    drumCounter
  ]);

  let ry = drumX; // radius along Y-axis, default 272 - 236
  let rx = drumY; // radius along X-axis, default 272 - 236

  const n = 35; // number of balls
  const so = 0; // starting offset

  return (
    <div
      className="lottery-drum"
      id="wheelHolder"
      style={
        { 
          display: props?.tab?.visible
        }
      }
    >
      {props.gameStatus === 1 ? (
        <img
          style={{ transform: `rotate(${arrowDeg}deg)` }}
          className="drum-arrow"
          src={arrowDrum}
          alt=""
        />
      ) : null}
      <div className="lottery-drum-balls-holder">
        {Array.from(localDrawnNumbers, (ball, index) => (
          <LotteryBall
            active={drumCounter === index + 1 ? true : false}
            key={index}
            label={ball}
            style={{
              top:
                (ry +
                  -ry * Math.cos((360 / n / 180) * (index + so) * Math.PI)) /
                2,
              left:
                (rx + rx * Math.sin((360 / n / 180) * (index + so) * Math.PI)) /
                2,
            }}
          />
        ))}
      </div>
      <div className="lottery-drum-background"></div>
      <div style={{ width: 210, height: 210, position: "relative" }}>
        <div className="drum-middle-holder">
          <img className="drum-middle" src={drumMiddle} alt="drum-middle" />
        </div>
        {useMemo(
          () => (
            <CircularProgressbarWithChildren
              counterClockwise
              minValue={0}
              maxValue={4} // hardcoded
              value={props.percentage}
            >
              {props.gameStatus === 0 ? (
                <div className={"lottery-drum-seconds"}>
                  <span className={"seconds " + (props.bounce ? "bouncing" : "")}>
                    {props.percentage}
                  </span>
                  <span className="seconds-mark-holder">s</span>
                </div>
              ) : (
                <div className="lottery-drum-number">
                  <div
                    className={
                      "lottery-drum-number-big " + (props.bounce ? "bouncing" : "")
                    }
                  >
                    {currentBigNumber === 0 ? null : currentBigNumber}
                  </div>
                  <div className="lottery-drum-number-odd">
                    {currentBigNumberOdd === 0 ? null : currentBigNumberOdd}
                  </div>
                </div>
              )}
            </CircularProgressbarWithChildren>
          ),
          [
            props.bounce,
            currentBigNumber,
            currentBigNumberOdd,
            props.gameStatus,
            props.percentage,
          ]
        )}
      </div>
    </div>
  );
}
