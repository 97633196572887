const wonGifUrls = [
  'https://media0.giphy.com/media/44gu1V41ejJni/giphy.gif',
  'https://media0.giphy.com/media/l1KUvnGPmDLIzGAEw/giphy.gif',
  'https://media4.giphy.com/media/3o7aCXMtmzXxu04JH2/giphy.gif',
  'https://media0.giphy.com/media/2zoCNKcWxeQVCyAHj2/giphy.gif',
  'https://media2.giphy.com/media/4QFAH0qZ0LQnIwVYKT/giphy.gif',
  'https://media.giphy.com/media/qJB8bovxCs9QA/giphy.gif',
  'https://media1.giphy.com/media/rofsCdigOv1wk/giphy.gif',
  'https://media3.giphy.com/media/lFfLINS1MkZs4/giphy.gif',
  'https://media.giphy.com/media/xT1XGXBercOLSE50Qg/giphy.gif',
  'https://media.giphy.com/media/K9lOuHVgENt5dUWQnf/giphy.gif',
  'https://media.giphy.com/media/8PmdoPk5JZpTEEy46I/giphy.gif'
];


export default wonGifUrls;
