// Dummy model for live game odds
// add number to push ball to the current result
let oddHolder = [
  {
    position: 1,
    odd: null,
  },
  {
    position: 2,
    odd: null,
  },
  {
    position: 3,
    odd: null,
  },
  {
    position: 4,
    odd: null,
  },
  {
    position: 5,
    odd: null,
  },
  {
    position: 6,
    odd: 25000,
  },
  {
    position: 7,
    odd: 10000,
  },
  {
    position: 8,
    odd: 5000,
  },
  {
    position: 9,
    odd: 2500,
  },
  {
    position: 10,
    odd: 1000,
  },
  {
    position: 11,
    odd: 500,
  },
  {
    position: 12,
    odd: 300,
  },
  {
    position: 13,
    odd: 200,
  },
  {
    position: 14,
    odd: 150,
  },
  {
    position: 15,
    odd: 100,
  },
  {
    position: 16,
    odd: 90,
  },
  {
    position: 17,
    odd: 80,
  },
  {
    position: 18,
    odd: 70,
  },
  {
    position: 19,
    odd: 60,
  },
  {
    position: 20,
    odd: 50,
  },
  {
    position: 21,
    odd: 40,
  },
  {
    position: 22,
    odd: 30,
  },
  {
    position: 23,
    odd: 25,
  },
  {
    position: 24,
    odd: 20,
  },
  {
    position: 25,
    odd: 15,
  },
  {
    position: 26,
    odd: 10,
  },
  {
    position: 27,
    odd: 9,
  },
  {
    position: 28,
    odd: 8,
  },
  {
    position: 29,
    odd: 7,
  },
  {
    position: 30,
    odd: 6,
  },
  {
    position: 31,
    odd: 5,
  },
  {
    position: 32,
    odd: 4,
  },
  {
    position: 33,
    odd: 3,
  },
  {
    position: 34,
    odd: 2,
  },
  {
    position: 35,
    odd: 1,
  },
];
export default oddHolder;
