import {useEffect, useState} from "react";
import Flag from "react-world-flags";
import calcComb from "../../services/Helpers/CalculateNumOfComb";
import label from "../../services/Translation/lang";
import ResultsBall from "./ResultsBall";
import oddHolder from "../../model/odd-holder";
import {currencyFormatter, formatNumber4} from "../../services/Helpers/NumberFormatter";
import storage from "../../services/Storage/Storage";
import ReactTooltip from "react-tooltip";
import ResultsBallSmall from "./ResultsBallSmall";

export default function ResultsBoxMyBetsOld(props) {
  const [onceTime, setOneTime] = useState(false);
  const [localRecords, setLocalRecords] = useState([]);
  const [localRecordsWinSum, setLocalRecordsWinSum] = useState(0);
  const [localIsWin, setLocalIsWin] = useState(false);
  const [stakeForRender, setStakeForRender] = useState(0);

  useEffect(() => {
    if (onceTime === false) {
      label.update();
      setOneTime(true);
    }
  }, [onceTime])

  useEffect(() => {
    if(props.indexArray.length === 35 && localRecords.length === 0 &&
      props.noRecord !== true) {
      setLocalRecords(props.indexArray);
    }
  }, [localRecords.length, props.indexArray, props.noRecord, props.roundNumber])

  useEffect(() => {
    if(props.indexArray.length === 35 && localRecords.length === 0) {
      /** Calculation for win */
      let winHandler = [];
      props.balls.forEach((ball) => {
        if(props.indexArray[props.indexArray.indexOf(ball)] === ball) {
          if(typeof oddHolder[props.indexArray.indexOf(ball)] !== 'undefined') {
            winHandler.push({
              winBall: ball,
              indexBall: props.indexArray.indexOf(ball),
              quota: props.indexArray.indexOf(ball) === 34 ? 1 : oddHolder[props.indexArray.indexOf(ball)].odd
            });
          }
        }
      });

      // This 'props.win > 0' sometimes comes with not valid values.
      // Frontend validation based on winning array
      if(winHandler.length > 5) {
        const numOfSumComb = calcComb(6, props.balls.length);
        let stakePerCombination = props.stake / numOfSumComb;
        winHandler.sort(function(a, b) {
          return parseFloat(a.indexBall) - parseFloat(b.indexBall);
        });
        let ticketWinSum = 0;
        let localHandlerOfWinCombination = {
          lastState: 0,
          finalState: 0
        };
        winHandler.forEach((item, index) => {
          if(index >= 5) {
            localHandlerOfWinCombination.finalState = calcComb(6, (index + 1)) - localHandlerOfWinCombination.lastState;
            ticketWinSum += stakePerCombination * localHandlerOfWinCombination.finalState * winHandler[index].quota;
            localHandlerOfWinCombination.lastState = calcComb(6, (index + 1));
          }
        });

        // ticketWinSum = parseFloat(ticketWinSum.toFixed(4));
        ticketWinSum = parseFloat(ticketWinSum);
        let test_ = parseFloat(storage.get('currency_exchange_rate'));
        if(typeof ticketWinSum === 'number') {
          // console.log('MY BETS OLD [0] WIN CHECK MAX!' + ticketWinSum + " vs " + parseFloat(storage.get('maxWin')))
          if(ticketWinSum > parseFloat(storage.get('maxWin'))) {
            setLocalRecordsWinSum(parseFloat(storage.get('maxWin')))
          } else {
            setLocalRecordsWinSum(ticketWinSum)
          }
        }
        setLocalIsWin(true);
        if(props.stake === props.baseStake && storage.get('userCurrency') !== 'EUR') {
          setStakeForRender(test_ * props.stake);
          if((test_ * ticketWinSum) > parseFloat(storage.get('maxWin'))) {
            setLocalRecordsWinSum(parseFloat(storage.get('maxWin')))
            // console.log('MY BETS OLD [1] WIN CHECK MAX!' + (test_ * ticketWinSum) + " vs " + parseFloat(storage.get('maxWin')))
          } else {
            setLocalRecordsWinSum(test_ * ticketWinSum)
          }
        } else {
          if(props.currency !== storage.get('userCurrency') &&
            storage.get('userCurrency') === 'EUR') {
            setStakeForRender(props.baseStake);
            if(props.win_eur > parseFloat(storage.get('maxWin'))) {
              // console.log('MY BETS OLD [2] WIN CHECK MAX!' + (props.win_eur) + " vs " + parseFloat(storage.get('maxWin')))
              setLocalRecordsWinSum(parseFloat(storage.get('maxWin')))
            } else {
              setLocalRecordsWinSum(props.win_eur)
            }
          } else {
            setStakeForRender(props.stake);
            if(props.win > parseFloat(storage.get('maxWin'))) {
              // console.log('MY BETS OLD [2] WIN CHECK MAX!' + (props.win) + " vs " + parseFloat(storage.get('maxWin')))
              setLocalRecordsWinSum(parseFloat(storage.get('maxWin')));
            } else {
              setLocalRecordsWinSum(props.win);
            }
          }
        }
      } else {
        // no win
        if(props.stake === props.baseStake && storage.get('userCurrency') !== 'EUR') {
          let test_ = parseFloat(storage.get('currency_exchange_rate'));
          setStakeForRender(test_ * props.stake);
        } else if(storage.get('userCurrency') !== 'EUR') {
          setStakeForRender(props.stake);
        } else {
          setStakeForRender(props.baseStake);
        }
        setLocalIsWin(false);
        setLocalRecordsWinSum(0);
      }
    }
  }, [props.indexArray, localRecords, props.roundNumber, props.noRecord, props.balls, props.stake, props.currency, props.baseStake, props.win, props.win_eur])

  return (
    <div className={localIsWin === true ? "results-box winner" : "results-box"}>
      {!props.withoutHeader ? (
        <div className="results-box-heder">
          <div className="results-user-avatar">
            <img src="/avatars/Avatar_0.svg" alt={props.username} />
          </div>
          <div className="results-user-name">{props.username}</div>
          <div className="results-user-flag">
            <Flag code={props.flag} height="10" width="14" />
          </div>
        </div>
      ) : null}
      <div className="results-balls-holder">
        {props.balls.reverse().map((ball, index) => {
          return (
            <ResultsBall key={index} label={ball}
              winBall={
                ((localRecords.length === 35 &&
                  localRecords[localRecords.indexOf(ball)] === ball)
                  ? true : false)
              }
            />);
        })}
      </div>
      <div className="results-box-footer">
        <div className="results-box-footer-left">
          <div className="results-box-footer-info">
            <label>{label.t("round")}:</label>
            {props.roundNumber}
          </div>
          <div className="results-box-footer-info">
            <label>{label.t("stake")}:</label>
            <div>
              {
                sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(stakeForRender, "###,###"):
                currencyFormatter(stakeForRender)
              }
              &nbsp;
              <span>{props.currency !== storage.get('userCurrency') ? storage.get('userCurrency') : props.currency}</span>
            </div>
          </div>
          <div className="results-box-footer-info">
            <label>{label.t("win")}:</label>
            <div>
              <span className="results-box-footer-info-win"> {
                  sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(localRecordsWinSum, "###,###"):
                  currencyFormatter(localRecordsWinSum)
              } </span>
              <span>{props.currency !== storage.get('userCurrency') ? storage.get('userCurrency') : props.currency}</span>
            </div>
          </div>
          <div className="results-box-footer-info" style={{marginRight: 0}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
              <span>{props.date}</span>
              <span>{props.time}</span>
            </div>
          </div>
        </div>
      </div>

      <div onClick={() => {
        if(document.getElementById("more" + props.roundNumber).style.display === 'flex') {
          document.getElementById("more" + props.roundNumber).style.display = 'none';
        } else {
          document.getElementById("more" + props.roundNumber).style.display = 'flex';
        }
      }} data-tip={label.t('showRoundNum')} className="more-info" data-langname="showRoundNum">i
        <ReactTooltip
          place="left"
          effect="solid"
          backgroundColor="#ffbf19"
          textColor='#1E3250'
        />
      </div>

      <div id={"more" + props.roundNumber} style={{display: 'none', flexWrap: 'wrap', margin: '9px 5px 4px 4px'}}>
        {props.indexArray.map((ball, index) => {
          return (
            <div key={index}>
              <small style={{display: 'block', fontSize: '9px', marginBottom: '0'}}>{index + 1}.</small>
              <ResultsBallSmall key={index} label={ball}
                winBall={
                  ((localRecords.length === 35 &&
                    props.balls.indexOf(ball) !== -1)
                    ? true : false)}
              />
            </div>
          )
        })}
      </div>

    </div >
  );
}
