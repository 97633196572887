
/**
 * LocalStorage
 */
class LocalStorage {

  set(key,valueToStore) {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log("There was an error storing " + key + "in local storage :" + error);
    }
  }

  get(key) {
    try {
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.log("There was an getting key " + key + "from local storage :" + error);
    }
  }

  remove(key) {
    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      console.log("There was an getting key " + key + "from local storage :" + error);
    }
  }

}

export default LocalStorage;
