import React, {createRef, useEffect, useMemo, useRef, useState} from "react";
import {ProgressBar} from "react-bootstrap";
import UserMessage from "../components/Chat/UserMessage";
import EmojiPickerSvg from "../components/svg/emoji-picker-svg";
import GifPickerSvg from "../components/svg/gif-picker-svg";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {avatars} from "../model/avatars";
import label from "../services/Translation/lang";
import EmojiPicker from "../components/Chat/Emojis/EmojiPicker";
import GifsPicker from "../components/Chat/Gifs/GifsPicker";
import soundEffect from "../services/Sounds/SoundFxPlayer";
import ChatBadge from "../components/Chat/ChatBadge";
import storage from "../services/Storage/Storage";

export default function Chat(props) {
  const [timeLeft, setTimeLeft] = useState(0);
  const [textContent, setTextContent] = useState("");
  const [chatSpam, showChatSpam] = useState(false);
  const charLimit = 160;
  const messagesEndRef = useRef(null);
  const chatContainer = useRef(null);
  const {width} = useWindowDimensions();
  // emoji
  const emojiRef = useRef(null);
  const gifsRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showGifs, setShowGifs] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [cursorPosition, setCursorPosition] = useState();
  const inputRef = createRef();

  const canShareGif = () => {
    if (timeLeft === 0) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      scrollToMyRef();
    }, 500);
  }, [width]);

  useEffect(() => {
    console.info("Chat");
  }, [props.net.updateChat, props.net.updateNewMsg]);

  // eslint-disable-next-line no-unused-vars
  const [clickedOutside, setClickedOutside] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const handleClickOutside = (e) => {
    if(!gifsRef.current.contains(e.target)) {
      setClickedOutside(true);
      setShowGifs(true);
    }

    if(!emojiRef.current.contains(e.target)) {
      setClickedOutside(true);
      setShowEmojis(false);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleShowEmojis = () => {
    inputRef.current.focus();
    setShowEmojis(!showEmojis);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setEmoji = ({emoji}) => {
    const ref = inputRef.current;
    ref.focus();
    const start = textContent.substring(0, ref.selectionStart);
    const end = textContent.substring(ref.selectionStart);
    const text = start + emoji + end;
    setTextContent(text);
    setCursorPosition(start.length + emoji.length);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setTimeLeft(4);
    setTextContent("");
    soundEffect.play('checking_for_spam');
    showChatSpam(true);
    setTimeout(function() {
      showChatSpam(false);
      props.net.requestSendChatMessage(textContent);
      soundEffect.play('posting');
      setTimeout(() => {
        scrollToMyRef();
      }, 500);
    }, 4 * 1000);
  };

  const isUrl = (msg) => {
    const regexGifUrl = msg.match(/.+?(giphy.gif)/);
    if(regexGifUrl) {
      return regexGifUrl[0];
    }
    return false;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitGif = (selectedGif) => {
    setShowGifs(true);
    setTimeLeft(4);
    // setTextContent("");
    showChatSpam(true);
    setTimeout(function() {
      showChatSpam(false);
      if(canShareGif()) {
        props.net.requestSendChatMessage(selectedGif);
        setTimeout(() => {
          scrollToMyRef();
        }, 500);
      } else {
        setTimeLeft(4);
      }
    }, 4 * 1000);
  };

  const scrollToMyRef = () => {
    try {
      const scroll =
        chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
      chatContainer.current.scrollTo({
        top: scroll,
        left: 0,
        behavior: "smooth",
      });
    } catch(err) {}
  };

  useEffect(() => {
    if(!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 0.1);
      if(timeLeft <= 0) {
        setTimeLeft(0);
      }
    }, 100);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnChange = (e) => {
    const charLimit = 160;
    if(charLimit - textContent.length <= charLimit) {
      setTextContent(e.target.value.substr(0, charLimit));
    }
    soundEffect.play('texting_1');
  };

  return useMemo(
    () => (
      <div className="chat-wrapper">
        <div className="chat-wrapper-online">
          <ChatBadge label={props.net.tOnline} />

        </div>
        <div className="chat-holder" ref={chatContainer}>
          {props.net.chat
            .slice(0)
            .reverse()
            .map((item, index) => {
              if(item.msg.indexOf("sharedBet") !== -1) return null;
              // console.log("???", item?.newMsg);
              return (
                <UserMessage
                  gifMessage={isUrl(item.msg)}
                  net={props.net}
                  key={index}
                  id={item.id}
                  currentUserMessage={
                    item.username === localStorage.getItem('username') || item.username === storage.get('serverUsername') ? true : false
                  }
                  newMessage={item?.newMsg ? true : false}
                  numberOfLikes={item.likes}
                  whoLikes={item.likes_who}
                  // numberOfFire={3}
                  img={avatars.sources[item.avatar_id > 0 ? item.avatar_id - 1 : 0]}
                  username={item.username}
                  flag={item.country_code}
                  time={item.time}
                  label={item.msg}
                />
              );
            })}
          <div className="end-message" ref={messagesEndRef} />
        </div>
        <form
          autoComplete="off"
          onSubmit={onFormSubmit}
          className="chat-page-input-holder"
        >
          {chatSpam ? (
            <div className="chat-spam">
              <p className="chat-spam-text">
                {label.t("checking_for_spam")}{" "}
                <span>{timeLeft.toFixed(0)}</span>
              </p>
              <ProgressBar
                max={0}
                min={3.5}
                now={4 - timeLeft}
              />
            </div>
          ) : null}
          <div className="chat-page-input-top">
            <input
              ref={inputRef}
              className="chat-page-input"
              name="content"
              value={textContent}
              onChange={handleOnChange}
              placeholder={label.t("write_a_replay")}
            />
            <p className="char-counter-holder">
              {charLimit - textContent.length <= 0 ? (
                <span className="counter-zero">0</span>
              ) : (
                <span>{charLimit - textContent.length}</span>
              )}
            </p>
            <div className="gif-emoji-holder">
            </div>
            <div ref={gifsRef} onClick={handleClickInside}>
              {!showGifs ? <GifsPicker submitGif={submitGif} /> : null}
              <div
                onClick={() => setShowGifs(!showGifs)}
                className="chat-button mr-3"
              >
                <GifPickerSvg className="me-3 cursor-pointer" />
              </div>
            </div>

            <div ref={emojiRef} onClick={handleClickInside}>
              <div
                className={
                  "emoji-container" + (showEmojis ? " " : " hide-emoji")
                }
              >
                {<EmojiPicker setEmoji={setEmoji} />}
              </div>
              <div className="chat-button" onClick={handleShowEmojis}>
                <EmojiPickerSvg className="cursor-pointer" />
              </div>
            </div>
          </div>
          <button
            disabled={timeLeft > 0 ? true : false}
            type="submit"
            style={{ position: 'absolute', left: -50}}
          >
            <img width="25px" src="./send.png" alt=""/>
          </button>
        </form>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.net,
      props.net.chat,
      chatSpam,
      timeLeft,
      inputRef,
      textContent,
      handleOnChange,
      showGifs,
      submitGif,
      showEmojis,
      setEmoji,
      handleShowEmojis,
    ]
  );
}
