import {urlParams} from "../UrlParamsLoader/UrlParamsLoader";

export const formatNumber = (n, p, ts, dp) => {
  var t = [];
  if(typeof p == 'undefined') p = 2;
  if(typeof ts == 'undefined') ts = ',';
  if(typeof dp == 'undefined') dp = '.';
  n = Number(n).toFixed(p).split('.');
  for(var iLen = n[0].length, i = iLen ? iLen % 3 || 3 : 0, j = 0;i <= iLen;i += 3) {
    t.push(n[0].substring(j, i));
    j = i;
  }
  return t.join(ts) + (n[1] ? dp + n[1] : '');
}

export const formatNumber4 = (n, p, ts, dp) => {
  var t = [];
  if(typeof p == 'undefined') p = 2;
  if(Boolean(urlParams.get("crypto")) === true) {p = 4;}
  if(typeof ts == 'undefined') ts = ',';
  if(typeof dp == 'undefined') dp = '.';
  n = Number(n).toFixed(p).split('.');
  for(var iLen = n[0].length, i = iLen ? iLen % 3 || 3 : 0, j = 0;i <= iLen;i += 3) {
    t.push(n[0].substring(j, i));
    j = i;
  }
  let preValue = t.join(ts) + (n[1] ? dp + n[1] : '');
  return preValue;
}

const customCurrencyFormatter = (amount, format) => {
  var formatedAmount = "";
  if(format === "###,###.00") {
    formatedAmount = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }
  if(format === "###,###K" || format === "###,###M") {
    if(amount >= 1000000) {
      formatedAmount = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount / 1000000) + "M";
    }
    else if(amount >= 1000) {
      formatedAmount = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount / 1000) + "K";
    } else {
      formatedAmount = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount);
    }
  }
  if(format === "###,###") {
    formatedAmount = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  }
  return formatedAmount;
};

export const currencyFormatter = (number, format) => {
  if(sessionStorage.getItem('amountFormat') === 'null') {format = null;}
  number = Number(number).toFixed(sessionStorage.getItem('DECIMALS'));
  if(format) {
    // console.log('FORMAT is defined = ', format)
    return customCurrencyFormatter(number, format);
  } else {
    // console.log('FORMAT is default...', format)
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: sessionStorage.getItem('DECIMALS'),
    }).format(number);
  }
};