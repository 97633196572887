import {useEffect, useState} from "react";
import Flag from "react-world-flags";
import {currencyFormatter, formatNumber4} from "../../services/Helpers/NumberFormatter";
import storage from "../../services/Storage/Storage";
import label from "../../services/Translation/lang";
import ResultsBall from "./ResultsBall";
import ReactTooltip from "react-tooltip";
import ResultsBallSmall from "./ResultsBallSmall";

export default function ResultsBoxMyBets(props) {
  const [localRecords, setLocalRecords] = useState([]);
  const [localRecordsWinSum, setLocalRecordsWinSum] = useState(0);
  const [localIsWin, setLocalIsWin] = useState(false);

  useEffect(() => {
    // console.log('GET localRecords.length' + localRecords.length)
    if(props.indexArray.length === 35 && /* && localRecords.length === 0  && */
      JSON.parse(storage.get('roundId')) === props.roundNumber /*&&
      props.noRecord !== true */ ) {
      setLocalRecords(props.indexArray);
    }
  }, [localRecords.length, props.indexArray, props.noRecord, props.roundNumber])

  useEffect(() => {
    if(localRecords.length === 0 && JSON.parse(storage.get('roundId')) === props.roundNumber) {
      if(storage.get('avoid') === true) {
        console.log(" avoid1 ...");
      } else {
        console.log(" avoid CORRECT ...");
        /** Calculation for win */
        if(props.activeTicketEmitedWin > 0) {
          setLocalIsWin(true);
        }
        if(props.activeTicketEmitedWin > parseFloat(storage.get('maxWin'))) {
          setLocalRecordsWinSum(parseFloat(storage.get('maxWin')));
          // console.log('MY BETS [1] WIN CHECK MAX!' + props.activeTicketEmitedWin + " vs " + parseFloat(storage.get('maxWin')))
        } else {
          setLocalRecordsWinSum(props.activeTicketEmitedWin);
        }
      }
    } else {
      if(JSON.parse(storage.get('roundId')) === props.roundNumber) {
        if(props.activeTicketEmitedWin > 0) {
          if(props.activeTicketEmitedWin > parseFloat(storage.get('maxWin'))) {
            setLocalRecordsWinSum(parseFloat(storage.get('maxWin')));
            // console.log('MY BETS [2] WIN CHECK MAX!' + props.activeTicketEmitedWin + " vs " + parseFloat(storage.get('maxWin')))
          } else {
            setLocalRecordsWinSum(props.activeTicketEmitedWin);
          }
          setLocalIsWin(true);
        }
      }
    }
  }, [localRecords.length, props.activeTicketEmitedWin, props.roundNumber]);

  return (
    <div className={localIsWin === true ? "results-box winner" : "results-box"}>
      {!props.withoutHeader ? (
        <div className="results-box-heder">
          <div className="results-user-avatar">
            <img src="/avatars/avatar-1.png" alt={props.username} />
          </div>
          <div className="results-user-name">{props.username}</div>
          <div className="results-user-flag">
            <Flag code={props.flag} height="10" width="14" />
          </div>
        </div>
      ) : null}
      <div className="results-balls-holder">
        {props.balls.map((ball, index) => {
          return (
            <ResultsBall key={index} label={ball}
              winBall={
                ((localRecords.length === 35 &&
                  localRecords[localRecords.indexOf(ball)] === ball)
                  ||
                  (JSON.parse(storage.get('roundId')) === JSON.parse(storage.get('betAckTicketData'))?.roundId &&
                    JSON.parse(storage.get('betAckTicketData'))?.roundId === props.roundNumber &&
                    props.indexArray[props.indexArray.indexOf(ball)] === ball)
                  ? true : false)
              }
            />);
        })}
      </div>
      <div className="results-box-footer">
        <div className="results-box-footer-left">
          <div className="results-box-footer-info">
            <label>{label.t("round")}:</label>
            {props.roundNumber}
          </div>
          <div className="results-box-footer-info">
            <label>{label.t("stake")}:</label>
            <div>
              <span>{
                sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(Number(props.stake), "###,###"):
                formatNumber4(props.stake)}</span> &nbsp;
              <span>{props.currency !== storage.get('userCurrency') ? storage.get('userCurrency') : props.currency}</span>
            </div>
          </div>
          <div className="results-box-footer-info">
            <label>{label.t("win")}:</label>
            <div>
              <span className="results-box-footer-info-win"> {
              sessionStorage.getItem('amountFormat') === '###,###' ? currencyFormatter(localRecordsWinSum, "###,###"):
              currencyFormatter(localRecordsWinSum)
              } </span>
              <span>{props.currency !== storage.get('userCurrency') ? storage.get('userCurrency') : props.currency}</span>
            </div>
          </div>
          <div className="results-box-footer-info" style={{marginRight: 0}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
              <span>{props.date}</span>
              <span>{props.time}</span>
            </div>
          </div>
        </div>
      </div>

      <div onClick={() => {
        if(document.getElementById("more" + props.roundNumber).style.display === 'flex') {
          document.getElementById("more" + props.roundNumber).style.display = 'none';
        } else {
          document.getElementById("more" + props.roundNumber).style.display = 'flex';
        }
      }} data-tip={label.t('showRoundNum')} className="more-info">i
        <ReactTooltip
          place="left"
          effect="solid"
          backgroundColor="#ffbf19"
          textColor='#1E3250'
        />
      </div>

      <div id={"more" + props.roundNumber} style={{display: 'none', flexWrap: 'wrap', margin: '9px 5px 4px 4px'}}>
        {(localRecords.length !== 35 ?
          (storage.get('roundId') === props.roundNumber ?
            props.indexArray.map((ball, index) => {
              return ( // in middle of round
                <div key={index}>
                  <small style={{display: 'block', fontSize: '9px', marginBottom: '0'}}>{index + 1}.</small>
                  <ResultsBallSmall key={index} label={ball}
                    winBall={
                      ((props.balls.indexOf(ball) !== -1) ? true : false)}
                  />
                </div>
              )
            }) :
            label.t('please')
          )
          :
          // localRecords storage.get('roundId') !== props.roundNumber
          (true ? localRecords.map((ball, index) => {
            return (
              <div key={index}>
                <small style={{display: 'block', fontSize: '9px', marginBottom: '0'}}>{index + 1}.</small>
                <ResultsBallSmall key={index} label={ball}
                  winBall={
                    ((localRecords.length === 35 &&
                      props.balls.indexOf(ball) !== -1)
                      ? true : false)}
                />
              </div>
            )
          }) : null)
        )
        }
      </div>
    </div >
  );
}
