import TransportAbstract from "./TransportAbstract";
import storage from "../Storage/Storage";
import soundEffects from "../Sounds/SoundFxPlayer";
import {toast} from 'react-toastify';
import {urlParams} from "../UrlParamsLoader/UrlParamsLoader";

export default class FastBallsTransport extends TransportAbstract {
  /**
   * Singleton FastBallsTransport
   * @param {*} uid
   * @param {*} serverKeepaliveTimeout
   * @param {*} secure_conn
   * @returns self
   */
  constructor(platformParams, serverKeepaliveTimeout, secure_conn = true) {
    super(platformParams, serverKeepaliveTimeout, secure_conn);

    if(FastBallsTransport.instance == null) {
      this.data = {};
      this.gameStarted = false;
      this.randLengthMultiplier = false;
      this.myCurrency = "";
      this.uncreditedWins = 0;
      this.countryCode = "";
      this.historyTabIndex = 0;
      this.username = "";
      this.chatFocused = false;
      this.once = false;
      this.zeroOnce = false;
      this.onceFillHistory = true;
      this.currRound = 0;
      this.myBetsByRound = [];
      this.onceTriggerRoundCounter = true;
      this.onceTriggerRoundCounterOff = true;
      this.firstLoad = true;
      this.maintenenceMode = false;
      this.button0AutobetDelay = false;
      this.button1AutobetDelay = false;
      this.weeklyTopWins = [];
      this.monthlyTopWins = [];
      this.yearlyTopWins = [];
      this.weeklyTopOdds = [];
      this.goToMaintenceMode = storage.get('goToMaintenceMode') || null;
      this.pBonusHistory = [];
      this.playFreeBets = false;
      this.freeTicketCount = 0;
      this.freeTicketsLength = 0;
      this.freeTicketMoney = 0;
      this.hasCashedOut = false;
      this.updateChat = false;
      this.animation = true;
      this.isolated = false;
      this.isolatedList = [];
      this.minBet = 30;
      this.maxBet = 500;
      this.maxWin = 100;
      this.defBet = 30;
      this.predefinedBets = [];
      this.buttons = {
        0: null,
        1: null
      }
      this.balance = 0;
      this.promoBalance = 0;
      this.monthlyTopOdds = [];
      this.yearlyTopOdds = [];
      this.weeklyBiggestOdds = [];
      this.monthlyBiggestOdds = [];
      this.yearlyBiggestOdds = [];
      this.myBetsList = [];
      this.cashouts = [];
      this.cashoutTick = false;
      this.sound = false;
      this.music = false;
      this.tempSound = false;
      this.tempMusic = false;
      this.myTicketIdentifier = "";
      this.autoBet = {
        0: false,
        1: false
      };
      this.tempBet = {};
      this.tempCancel = {};
      this.tempCashout = {};
      this.onceSnapFlag = true;
      this.multipliersHistory = {};
      this.allPlayersBetsByRound = [];

      //new launcher params
      this.launcherFullScreen = null;
      this.launcherAutoBet = null;
      this.launcherAutCashout = null;
      this.launcherBackUrl = null;
      this.launcherChatRoom = null;
      this.launcherLanguage = null;
      this.launcherHideHeader = null;
      this.launcherCountryCode = null;
      this.demoAutoBet = true;
      this.launcherCurrency = null;
      this.launcherUid = null;
      this.launcherCompanyId = null;
      this.launcherCrypto = null;
      this.launcherShowHelp = null;
      this.launcherToken = null;
      this.launcherVersion = null;
      this.loggedIn = false;
      this.platformId = null;

      this.JP1History = [];
      this.JP2History = [];
      this.JP3History = [];
      this.JackPotValues = {};
      this.JackPotValuesNames = [];
      this.noOfDecimals = (Boolean(urlParams.get("crypto")) === true ? 4 : 2);
      sessionStorage.setItem('DECIMALS', this.noOfDecimals);
      this.tOnline = 0;

      FastBallsTransport.instance = this;

      this.JPUpdate = false;
      window.addEventListener("update-jackpots", () => {
        this.requestJackpotData(this.platformParams.companyId);
      }, {passive: true});

      window.addEventListener("update-bonus-history", () => {
        this.requestPlayerBonusHistory(this.platformParams.companyId);
      }, {passive: true});

      window.addEventListener("update-balance", () => {
        this.requestUserBalance();
      }, {passive: true});

    }
    return FastBallsTransport.instance;
  }

  connected = () => {
    let dc = new CustomEvent("connected", {
      detail: {info: 'nice'}
    });
    dispatchEvent(dc);

    storage.set('preventDC', null);
  }

  onDisconnect = (e) => {
    console.log('Disconnected =>', e.message)
    this.sound = false;
    let dc = new CustomEvent("disconnected", {
      detail: {info: e.message}
    });
    dispatchEvent(dc);
  }

  sessionStatus(data) {
    console.log('sessionStatus => ', data)
    if(data === "STALE") {
      this.disconnect();
    }
  }

  login = (e) => {
    console.log(`%c Login Success. ${e}`,
      "background-color:#000;color: #fff; font-size:15px;text-shadow: 1px 1px 4px;");
    // TO DO CHECK IN lancherParams for exchange rate data
    if(e.player_data.currency_exchange_rate === null) {
      console.log('spec:case:currency_exchange_rate:null')
      this.myExchangeRate = 1;
      storage.set('currency_exchange_rate', this.myExchangeRate);
    } else {
      this.myExchangeRate = e.player_data.currency_exchange_rate;
      storage.set('currency_exchange_rate', this.myExchangeRate);
    }

    let gameInfo = new CustomEvent("gameInfo", {
      detail: {
        info: {
          sid: e.sid,
          nextTicket: e.open_tickets,
          isolated: e.isolated,
          isolatedList: e.isolated_list,
          rtp: e.fastballs_rtp,
          quotas: e.fastballs_odds,
          // fill all bets and my bets tabs
          allBets: e.existing_current_and_next_round_tickets,
          myBets: e.player_ticket_history
        }
      }
    });
    dispatchEvent(gameInfo);

    let betInfo = new CustomEvent("playerInfo", {
      detail: {info: e.player_data}
    });
    dispatchEvent(betInfo);

    const historyInfo = new CustomEvent("historyInfo", {
      detail: {info: e.numbers_history}
    });
    dispatchEvent(historyInfo);
    this.requestJackpotData(this.platformParams.companyId);
    this.requestJackpotHistory(1);
    this.requestJackpotHistory(2);
    this.requestJackpotHistory(3);
    this.requestTopWinsWeekly();
    this.requestTopOddsWeekly();
    dispatchEvent(new CustomEvent("update-bonus-history", {detail: {}}));
  }

  gameStateUpdate = (e) => {
    const gameStateInfo = new CustomEvent("gameStateInfo", {
      detail: {info: e}
    });
    dispatchEvent(gameStateInfo);

    const getAnimationDeltaTime = new CustomEvent("getAnimationDeltaTime", {
      detail: {info: e}
    });
    dispatchEvent(getAnimationDeltaTime);
  }

  betAck = (e) => {
    const betAck = new CustomEvent("betAck", {
      detail: {info: e}
    });
    dispatchEvent(betAck);
  }

  tickBets = (e) => {
    this.dlog(`TBI tickBets, got ${JSON.stringify(e)}`)
    const tickBets = new CustomEvent("tickBets", {
      detail: {info: e}
    });
    dispatchEvent(tickBets);
  }

  ticketDetails = (e) => {
    console.log("tickets details=>", e);
  }

  setAvatar = (a) => {
    this.avatarId = a;
  }

  getRandom = (min, max) => {
    return parseFloat((Math.random() * (max - min) + min).toFixed(2));
  }

  jackpotData = (e) => {
    // console.log('JP datad key', e)
    var DELTA = 1;
    if(storage.get('userCurrency') !== 'EUR') {DELTA = 0.01}

    if(this.JackPotValuesNames.length === 0) {
      for(let key in e) {
        if(e[key] > 1) this.JackPotValues[key] = e[key] - DELTA;
        if(e[key] <= 1) this.JackPotValues[key] = e[key] + DELTA;
        if(this.JackPotValues[key].toString().indexOf('.') === -1) {
          this.JackPotValues[key] += 0.11;
        }
        this.JackPotValuesNames.push(key);
      }
      this.JPUpdate = !this.JPUpdate;
    } else {
      for(let key in e) {
        if(e[key] > 1) this.JackPotValues[key] = e[key] - DELTA;
        if(e[key] <= 1) this.JackPotValues[key] = e[key] + DELTA;
        if(this.JackPotValues[key].toString().indexOf('.') === -1) {
          this.JackPotValues[key] += 0.11;
        }
      }
      soundEffects.play('shuffle');
      this.JPUpdate = !this.JPUpdate;
    }
  }

  jackpotHistory = (e) => {
    // console.log('>>>>>>>', e)
    if(e.length > 0) {
      if(e[0].jackpot_level === 1) {
        this.JP3History = e;
      } else if(e[0].jackpot_level === 2) {
        this.JP1History = e;
      } else if(e[0].jackpot_level === 3) {
        this.JP2History = e;
      }
    }
  }

  playerBonusHistory = (e) => {
    function convertUTCDateToLocalDate(date) {
      var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();
      newDate.setHours(hours - offset);
      return newDate; 
    }
    // console.log(">>>>i>", e)
    // predpostavka 13 -> free ticket
    //              12 -> promo credit
    this.pBonusHistory = [];
    e.forEach((i) => {
      // console.log(">>>>i>", i)
      var t = convertUTCDateToLocalDate(new Date(i.bonus_time.replace(' ', 'T')))
      var nl = t.toLocaleTimeString()
      nl = nl.split(' ')[0]
      var N = t.toISOString()
      var date = N.split("T")[0];
      date = date.replace('-', '.')
      date = date.replace('-', '.')
      var dateY = date.split(".")[0]
      var dateM = date.split(".")[1]
      var dateD = date.split(".")[2]
      var constructMe = dateD + '.' + dateM + '.' + dateY;
      let bonusType = "";
      if(i.bonus_type === '2' || i.bonus_type === '3' || i.bonus_type === '12') {
        // passed!
      } else {
        console.log("dont show any other except 2,3,12  !");
        return;
      }
      if(i.bonus_type === '2') bonusType = 'GrandPot';
      if(i.bonus_type === '3') bonusType = 'Fastpot';
      if(i.bonus_type === '12') bonusType = 'Promo Credit';
      let item = {
        currencyCode: i.currency_code,
        bonusType: bonusType,
        win: i.win_amount,
        winCount: i.win_count,
        date: constructMe,
        time: nl,
      };
      this.pBonusHistory.push(item);
    });

  }

  totalOnline = (e) => {
    this.tOnline = e;
  }

  betCancelAck = (e) => {
    let cancelBet = new CustomEvent('cancel-bet', {detail: {info: e.bet_cancel_ack, self: this}});
    dispatchEvent(cancelBet);
  }

  tickCanceledBets = (e) => {
    for(var key in e) {
      let cancelBet = new CustomEvent('cancel-all-bet', {detail: key});
      dispatchEvent(cancelBet);
    }
  }

  userBalance = (e) => {
    // console.log('BALANCE COMMING HARD CODE: ', e)
    // e.balance = 99000000000;
    // e.promobalance = 12;
    let balanceUpdate = new CustomEvent('balance-update', {detail: e})
    dispatchEvent(balanceUpdate);
  }

  notification(e) {
    let notify = new CustomEvent('on-direct-message', {detail: e});
    dispatchEvent(notify);
  }

  exception(e) {
    console.log("Error detected: ", e.desc);
    var errorBets = ['Betting disabled', 'Bet already active', 'Bet failed internally'];
    errorBets.forEach((i) => {
      if(e.desc.indexOf(i) !== -1) {
        setTimeout(() => {
          storage.set('preventDC', null);
          console.info("place_bet is free for call now.");
        }, 1000)
      }
    })
    // "desc": Betting disabled
    // "desc": Bet already active
    // "desc": PLATFORM ERROR: ...
    // "desc": Bet Request to platform failed
    // "desc": Bet failed internally
    var printText = e.desc;
    // text comes from server not multilang support on server
    // hardcode exception for OG-680 excel doc line 10
    if (printText.indexOf('funds') !== -1) {
      printText = printText.replace('funds', 'coins')
      console.log('special exception done. OG-680')
    }

    toast.error(() => {return <>🤨 {printText} <small> - click to copy</small> </>}, {
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onOpen: function() {
        if(typeof navigator.clipboard !== 'undefined') {
          navigator.clipboard.writeText(this.children.props.children[1]).then(function() {
            console.log('Copying to clipboard was successful!');
          }, function(err) {
            console.error('Could not copy text: ', err);
          });
        }
      }
    });
  }

  topWinsWeekly(data) {
    let topWins = [];
    data.forEach((item) => {
      let topWinsItem = {};
      let numbers = item.numbers.split(",");
      numbers.pop();
      let test = item.created_time.split(' ');
      let betTime = test[1];
      let betDate = test[0];
      betDate = betDate.split('-', 3);
      betDate = betDate[1] + '.' + betDate[2] + '.';
      betTime = betTime.split('.')[0];
      betTime = betTime.split(':', 2);
      betTime = betTime[0] + ':' + betTime[1];
      topWinsItem.numbers = numbers;
      topWinsItem.avatarId = item.avatar_id;
      topWinsItem.bet = item.bet;
      topWinsItem.betEur = item.bet_eur;
      topWinsItem.countryCode = item.country_code;
      topWinsItem.currencyCode = item.currency_code;
      topWinsItem.createdTime = betTime;
      topWinsItem.createdDate = betDate;
      topWinsItem.roundId = item.round_id;
      topWinsItem.ticketId = item.ticket_id;
      topWinsItem.username = item.username;
      topWinsItem.win = item.win;
      topWinsItem.winEur = item.win_eur;
      topWins.push(topWinsItem);
    });
    let u = new CustomEvent('topwins-update', {detail: topWins})
    dispatchEvent(u);
  }

  topWinsMonthly(data) {
    this.dlog(`TBI topWinsMonthly, got ${JSON.stringify(data)}`)
  }

  topWinsYearly(data) {
    this.dlog(`TBI topWinsYearly, got ${JSON.stringify(data)}`)
  }

  topOddsWeekly(data) {
    let topWins = [];
    data.forEach((item) => {
      let topWinsItem = {};
      let numbers = item.numbers.split(",");
      numbers.pop();
      let test = item.created_time.split(' ');
      let betTime = test[1];
      let betDate = test[0];
      betDate = betDate.split('-', 3);
      betDate = betDate[1] + '.' + betDate[2] + '.';
      betTime = betTime.split('.')[0];
      betTime = betTime.split(':', 2);
      betTime = betTime[0] + ':' + betTime[1];
      topWinsItem.numbers = numbers;
      topWinsItem.avatarId = item.avatar_id;
      topWinsItem.bet = item.bet;
      topWinsItem.betEur = item.bet_eur;
      topWinsItem.countryCode = item.country_code;
      topWinsItem.currencyCode = item.currency_code;
      topWinsItem.createdTime = betTime;
      topWinsItem.createdDate = betDate;
      topWinsItem.roundId = item.round_id;
      topWinsItem.ticketId = item.ticket_id;
      topWinsItem.username = item.username;
      topWinsItem.win = item.win;
      topWinsItem.winEur = item.win_eur;
      topWins.push(topWinsItem);
    });
    let u = new CustomEvent('topodds-update', {detail: topWins})
    dispatchEvent(u);
  }

  topOddsMonthly(data) {
    this.dlog(`TBI topOddsMonthly, got ${JSON.stringify(data)}`)
  }

  topOddsYearly(data) {
    this.dlog(`TBI topOddsYearly, got ${JSON.stringify(data)}`)
  }

}
