import loadingScreenBackground from "../../assets/images/loading-screen-background-low.webp";
import logo from "../../assets/images/logo.png";
import FadeIn from "react-fade-in";
import {useEffect, useState} from "react";
import storage from "../../services/Storage/Storage";
import {toast} from "react-toastify";

export default function NotificationPopUp(props) {
  const [selfDestroy, setSelfDestroy] = useState(false);
  const [promobalance, setPromobalance] = useState(false);
  const [typeOfJP, setTypeOfJP] = useState(false);

  useEffect(() => {
    if (props.notification.type === 'PROMOCREDIT') {
      let value = props.notification.msg.split(' ')[0]
      // setPromobalance(parseFloat(storage.get('currency_exchange_rate')) * value);
      setPromobalance(parseFloat(value));
      props.setPromoBalance(parseFloat(value));
    }

    if (props.notification.type === 'JACKPOTWINNER') {
      let data = props.notification.msg.split(';');
      let typeOfJP = data[0];

      if (typeOfJP === '2') {
        // console.log('TEST JP TYPE YES 2==grand : ' , typeOfJP)
        setTypeOfJP(props.net.JackPotValuesNames[0]);
      } else if (typeOfJP === '3') {
        // console.log('TEST JP TYPE YES 3=fast : ' , typeOfJP)
        setTypeOfJP(props.net.JackPotValuesNames[1]);
      }

      // setTypeOfJP(props.net.JackPotValuesNames[typeOfJP-1]);
      let value = data[1].split(' ')[0];
      // setPromobalance(parseFloat(storage.get('currency_exchange_rate')) * value);
      setPromobalance(parseFloat(value));
    }

    if (props.notification.type === "SOMEONEWONJACKPOT") {
      let data = props.notification.msg.split(";");
      let typeOfJP = data[0];
      console.log('TEST JP TYPE [others]: ' , typeOfJP)
      let amount = data[1];
      let name = data[2];
      toast.info(
        () => {
          return (
            <>
              {props.label.t("jackpot_type")} : {props.net.JackPotValuesNames[parseInt(typeOfJP - 1)]}
              <br />
              {props.label.t("amount")} : {amount}
              <br />
              {props.label.t("player")} : {name}
            </>
          );
        },
        {
          toastId: 'no-duplicate',
          theme: "dark",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: false,
        }
      );
    }

    // Timeout destroy
    if (selfDestroy === false) {
      setSelfDestroy(true);
      setTimeout(()=> {
        props.setNotificationPopUpVisibility(false);
      }, 5000);
    }

  }, [props, selfDestroy])

  return (
    props.notification.type !== 'SOMEONEWONJACKPOT' && 
    (props.notification.type === 'PROMOCREDIT' || props.notification.type === 'JACKPOTWINNER') ?
     <div className={"notify-screen"} onClick={()=>{
      props.setNotificationPopUpVisibility(false);
     }}>
      <div className="loading-screen-background" style={{zIndex: -1}}>
        <img
          src={loadingScreenBackground}
          alt="loading-background"
        />
      </div>
      <FadeIn style={{zIndex: 1000000, padding: '12px', textAlign: 'center'}} delay={200}>
        <div style={{fontFamily: '"Kanit-Medium", Roboto-Medium', fontSize: '40px', paddingBottom: '20px', color: '#fff'}}>

          {props.notification.type === 'PROMOCREDIT' ?
            <>
              <h1 style={{textAlign: 'center'}}>{props.notification.type}</h1>
              <h1 style={{textAlign: 'center'}}>{props.label.t('winner')} <b style={{color: '#f7ff00'}}>{storage.get('serverUsername')}</b> </h1>
              <h1 style={{textAlign: 'center'}}>{promobalance} {storage.get('userCurrency')} </h1> </> :
            null}

          {props.notification.type === 'JACKPOTWINNER' ?
            <>
              <h1 style={{textAlign: 'center'}}>{props.notification.type}</h1>
              <h1 style={{textAlign: 'center'}}>{typeOfJP}</h1>
              <h1 style={{textAlign: 'center'}}>{props.label.t('winner')} 
                &nbsp;
                <b style={{color: '#f7ff00'}}>
                  {localStorage.getItem('username') !== "empty" && 
                   localStorage.getItem('username') !== null ? localStorage.getItem('username'): storage.get('serverUsername')}
                </b> </h1>
              <h1 style={{textAlign: 'center'}}>{promobalance} {storage.get('userCurrency')} </h1> </> :
            null}

        <div style={{marginTop: '100%', marginBottom: '-117%'}}>
          <img
            src={logo}
            width="250px"
            alt="loading-background"
          />
        </div>

        </div>
      </FadeIn>
    </div> : null
  );
}
