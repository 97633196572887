import { Accordion } from "react-bootstrap";
import BetRoundBox from "./BetRoundBox";
import FadeIn from "react-fade-in";

export default function BetRounds(props) {
  if (typeof props === 'undefined') return null;
  return (
    <div className="results-tab">
      <Accordion className="bet-rounds" defaultActiveKey={0}>
        <FadeIn>
          {props.historyData.map((round, index) => {
            return (
              <BetRoundBox
                key={index}
                eventKey={index}
                roundId={round.roundId}
                date={round.date}
                time={round.time}
                balls={round.numbers}
              />
            );
          })}
        </FadeIn>
      </Accordion>
    </div>
  );
}
