import { useState } from "react";
import label from "../../services/Translation/lang";
import loadingScreenBackground from "../../assets/images/loading-screen-background-low.webp";
import FadeIn from "react-fade-in";
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

export default function Username(props) {
  const [nick, setNick] = useState("");
  const [nickValidated, setNickValidated] = useState(true);
  return (
     <div className={"loading-screen-inapp"}>
      <div className="loading-screen-background" style={{zIndex: -1}}>
        <img
          src={loadingScreenBackground}
          alt="loading-background"
        />
      </div>
      <FadeIn style={{zIndex: 10000000, padding: '12px', textAlign: 'center'}} delay={200}>
         <div style={
          {
            fontFamily: '"Kanit-Medium", Roboto-Medium',
            paddingBottom: '20px',
            color: '#fff',
            fontSize: '24px'
          }}>{label.t('please_username')}</div>
         <p></p>
         <input
              style={{width: '100%', zIndex: 10000000}}
              className="choose-amount-input"
              type="text"
              min={4}
              max={15}
              placeholder={label.t('username')}
              value={nick}
              onChange={(e) => {
                setNick(e.currentTarget.value);
                if (nickValidated === false) {
                  setNickValidated(true);
                }
              }}
              onKeyDown={(e)=> {
                if (nick.length < 3 && nick.length !== 0) {
                  setNickValidated(false);
                  return;
                }
                if (e.code === 'Enter') {
                  try {
                    if (e.currentTarget.value !== "") {
                      localStorage.setItem('username', e.currentTarget.value);
                    } else {
                      localStorage.setItem('username', "empty");
                    }
                    // eslint-disable-next-line no-self-assign
                    window.location.href = window.location.href;
                    props.setShowUsernameComp(false);
                  } catch(err) {}
                }
              }}
            />
            <span style={{display: (nickValidated === false ? 'block' : 'none'), color: "red"}}>
              {label.t('nick_validation_1')}⚠
            </span>
            <p></p>
              <PrimaryButton
                      small
                      onClick={() => {
                        if (nick.length < 3 && nick.length !== 0) {
                          setNickValidated(false);
                          return;
                        }
                        if (nick !== "") {
                          localStorage.setItem('username', nick);
                        } else {
                          localStorage.setItem('username', "empty");
                        }
                        // flag for start up only procedure
                        props.setIsNicknameAdded(true);
                        if (typeof props.changeUsername !== 'undefined') {
                          props.changeUsername(nick);
                          // console.log(" net changeUsername is undefined");
                        }
                        props.setShowUsernameComp(false);
                        // eslint-disable-next-line no-self-assign
                        window.location.href = window.location.href;
                      }}
                      label={label.t("save")}
                    />
                <p></p>
              <SecondaryButton
                      small
                      onClick={() => {
                        props.setShowUsernameComp(false);
                      }}
                      label={label.t("cancel")}
                    />
      </FadeIn>
    </div>
  );
}
