import * as React from "react";

const TrashCanSvg = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.25 2V.5h7.5V2h3.75v1.5H14v11.25a.75.75 0 0 1-.75.75H2.75a.75.75 0 0 1-.75-.75V3.5H.5V2h3.75ZM3.5 3.5V14h9V3.5h-9Zm2.25 2.25h1.5v6h-1.5v-6Zm3 0h1.5v6h-1.5v-6Z"
      fill="#777C8F"
    />
  </svg>
);

export default TrashCanSvg;
