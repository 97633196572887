import * as React from "react";

function CreditPromoSvg(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      {...props}
    >
      <circle cx={10} cy={10} r={10} fill="#3db2f5" />
      <path
        d="M9.226 13.792a27.392 27.392 0 01-2.002-.196v-1.68c.933.075 1.69.112 2.268.112.448 0 .765-.014.952-.042.196-.037.317-.093.364-.168.056-.075.084-.2.084-.378 0-.15-.014-.252-.042-.308-.028-.065-.084-.112-.168-.14a2.752 2.752 0 00-.448-.084l-1.4-.182c-.523-.065-.915-.2-1.176-.406a1.522 1.522 0 01-.532-.77C7.042 9.242 7 8.845 7 8.36c0-.784.187-1.34.56-1.666.383-.327.938-.518 1.666-.574V5h1.652v1.106c.7.028 1.288.093 1.764.196v1.68a23.053 23.053 0 00-2.1-.112c-.42 0-.723.01-.91.028-.177.019-.303.065-.378.14-.075.065-.112.182-.112.35 0 .159.01.27.028.336.028.056.084.098.168.126.084.028.233.056.448.084l1.568.182c.41.056.733.15.966.28.243.13.425.35.546.658.121.308.182.756.182 1.344 0 .868-.182 1.475-.546 1.82-.364.336-.905.527-1.624.574v1.064H9.226v-1.064z"
        fill="#070A2A"
      />
    </svg>
  );
}

export default CreditPromoSvg;
