import handleNumber from "../../services/Helpers/HandleNumber";

export default function ChooseNumbersBall(props) {
  return (
    <div
      className={
        "choose-numbers-ball-holder " + (props.disabled ? "disabled" : "")
      }
    >
      <div className="choose-numbers-ball-wrapper">
        <button
          className={
            "choose-numbers-ball " +
            (props.activeLabel.length > 0 ? "active " : " ") +
            handleNumber(props.label)
          }
          disabled={props.disabled}
          onClick={props.onClick}
        >
          <div className="choose-numbers-ball-border">{props.label}</div>
        </button>
      </div>
    </div>
  );
}
