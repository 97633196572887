import handleNumber from "../../services/Helpers/HandleNumber";

export default function CurrentRoundBall(props) {
  return (
    <div className="current-round-ball-holder">
      <div className="bet-rounds-ball-position">{props.position}</div>
      <div className="current-round-ball-wrapper">
        <div className={"current-round-ball " + handleNumber(props.label)}>
          {props.label ? (
            <div className="current-round-ball-border">{props.label}</div>
          ) : null}
        </div>
      </div>
      <div className={ props.bonus === 0 ? "bet-rounds-ball-odd":"bet-rounds-ball-odd-bonus"}>{props.odd}</div>
    </div>
  );
}
