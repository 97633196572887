import * as React from "react";

const GifPickerSvg = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm4.415 13c-1.115 0-1.963-.253-2.544-.76C3.291 11.73 3 11.01 3 10.082c0-.97.297-1.726.891-2.268C4.485 7.271 5.36 7 6.515 7c.393 0 .747.021 1.061.063.32.042.624.106.911.19V8.5a4.408 4.408 0 0 0-.901-.199 8.135 8.135 0 0 0-.951-.054c-.694 0-1.202.15-1.522.452-.314.295-.47.756-.47 1.382 0 .572.15.997.45 1.274.3.271.744.407 1.332.407.387 0 .747-.048 1.08-.145v-1.102h-1.39v-.913h2.743v2.883c-.227.132-.544.253-.952.361-.4.103-.897.154-1.491.154Zm3.268-.09V7.09h1.613v5.82H9.683Zm2.63-5.82v5.82h1.613v-2.34h2.704V9.43h-2.704V8.21H17V7.09h-4.686Z"
      fill="#515877"
    />
  </svg>
);

export default GifPickerSvg;
