import React, {useEffect, useMemo, useState} from "react";
import {Nav, Tab} from "react-bootstrap";
import ChooseAmount from "../components/ChooseAmount/ChooseAmount";
import ChooseNumbers from "../components/ChooseNumbers/ChooseNumbers";
import LiveGame from "../components/LiveGame/LiveGame";
import AutoplayPopUp from "../components/PopUps/AutoplayPopUp";
import YourTicket from "../components/Ticket/YourTicket";
import ActiveTicket from "../components/Ticket/ActiveTicket";
import Chat from "./Chat";
import Results from "./Results";
import SecondaryButton from "../components/Buttons/SecondaryButton";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import label from "../services/Translation/lang";
import storage from "../services/Storage/Storage";
import {isMobile} from "../services/Helpers/checkPlatform";
import BottomNavigator from "../components/Main/BottomNavigator";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Username from "../components/Username/UserName";
import soundEffect from "../services/Sounds/SoundFxPlayer";
import ChangeAvatar from "../components/Avatar/ChangeAvatar";
import {checkIsolated, devIsolated} from "../services/Helpers/checkIsolated";
import oddHolder from "../model/odd-holder";
import NewMsgNotify from "../components/Chat/NewMsgNotify";
import {toast} from "react-toastify";

export default function PlayComponent(props) {
	const [activeTab, setActiveTab] = useState("bets");
	const {width} = useWindowDimensions();
	const [autoPlayShow, setAutoPlayShow] = useState(false);
	const [autoPlayUpdateMe, setAutoPlayUpdateMe] = useState(0);
	const [loadingScreen, showLoadingScreen] = useState(true);
	const [autoPlayRounds, setAutoPlayRounds] = useState(0);
	// const [autoPlayCurrentRound, setAutoPlayCurrentRound] = useState(1);
	const [autoPlay, setAutoPlay] = useState(false);
	// Depending on the gameStatus we are showing components in PlayComponent
	// When the gameStatus is 1 or the betStatus is 1 betting should be disabled on Desktop and not shown on mobile
	const [gameStatus, setGameStatus] = useState(0);
	const [betStatus, setBetStatus] = useState(0);
	// MAX PAYOUT
	const [maxWin, setMaxWin] = useState(10000);
	// Min/Max bet amount must come from server
	const [minBetAmount, setMinBetAmount] = useState(0.1);
	const [maxBetAmount, setMaxBetAmount] = useState(1000);
	const [minBetPerComb, setMinBetPerComb] = useState(0.0108);
	// Relation between YourTicket vs ChooseAmount
	const [currentBetAmount, setCurrentBetAmount] = useState(0.10);
	const [prefinedBets, setPrefinedBets] = useState([0.1, 0.2, 0.5, 1]);
	// Currency
	const [currencyCode, setCurrencyCode] = useState("EUR");
	// sid
	const [sid, setSid] = useState("");
	// Username
	const [username, setUsername] = useState("");
	// const [myAvatarId, setMyAvatarId] = useState(0);
	const [countryCode, setCountryCode] = useState("RS");
	const [betAckTicketData, setBetAckTicketData] = useState({});
	// Selected numbers state
	const [ticketNumbers, setTicketNumbers] = useState([]);
	const [clearChoosenNumbers, setClearChoosenNumbers] = useState(false);
	// Send from active ticket result to myBet current ticket optimisation
	const [activeTicketEmitedWin, setActiveTicketEmitedWin] = useState(0);
	// Most important - current round balls
	const [indexArray, setIndexArray] = useState([]);
	const [indexArrayQuota, setIndexArrayQuota] = useState([]);
	const [activeTicketNumbers, setActiveTicketNumbers] = useState([]);
	const [activeTicketCurrentBet, setActiveTicketCurrentBet] = useState(0);
	const [bounce, setBounce] = useState(false);
	const [bonus, setBonus] = useState(0);
	const [drawnNumbers, setDrawnNumbers] = useState([]);
	const [percentage, setPercentage] = useState(4);
	const [roundID, setRoundID] = useState(0);
	const [nextRs, setNextRs] = useState(5);
	// Tabs , AllBets, MyBets
	const [allBetsItems, setAllBetsItems] = useState([]);
	const [allBetsPreviousHand, setAllBetsPreviousHand] = useState([]);
	const [myBetsItems, setMyBetsItems] = useState([]);
	const [topWinsItems, setTopWinsItems] = useState([]);
	const [topOddsItems, setTopOddsItems] = useState([]);
	const [localRecordsPrevius, setLocalRecordsPrevius] = useState([]);
	// loaded my bets data from server
	const [myBetsItemsFromServer, setMyBetsItemsFromServer] = useState([]);
	// history comp
	const [historyData, setHistoryData] = useState([]);
	// game state staff
	const [ballInterval, setBallInterval] = useState(500);
	const [isAppEventAttached, setIsAppEventAttached] = useState(false);
	const [isAppEventAttached2, setIsAppEventAttached2] = useState(false);
	const [mustBeDraw, setMustBeDraw] = useState(0);

	useEffect(() => {
		// Logic for mobile dep - for now it is
		// simple innerWidth can be determinate by device info.
		if(isMobile() === false) {
			setActiveTab('bets');
		}
	}, [width])

	useEffect(() => {
		if(isAppEventAttached === false) {
			setIsAppEventAttached(true);
			// setAutoPlayRounds(parseFloat(storage.get('autoPlaySumOfRound')));

			window.addEventListener("gameInfo", (e) => {
				console.log("App event gameInfo ->", e.detail.info);
				storage.set('isolated', e.detail.info.isolated);
				const locIsolatedList = e.detail.info.isolatedList.split(',')
				storage.set('isolatedList', locIsolatedList);
				props.setRtp(e.detail.info.rtp.toFixed(2));
				for(var j = 5;j < 35;j++) {
					oddHolder[j].odd = e.detail.info.quotas[j + 1]
				}
				setSid(e.detail.info.sid);
				storage.set("sid", e.detail.info.sid);

				const nt = e.detail.info.nextTicket;
				let t = null;
				for(let key in e.detail.info.nextTicket) {
					t = nt[key];
				}
				if(t !== null) {
					let tnumbers = t.numbers.split(",");
					tnumbers.pop();
					tnumbers = tnumbers.map((num_) => parseInt(num_, 10));
					setActiveTicketCurrentBet(parseFloat(t.bet));
					setActiveTicketNumbers(tnumbers);
					storage.set("ticketNumbers", tnumbers);
					storage.set("lastPlayedTicked", tnumbers)
					setBetStatus(1);
				}

				// All Bets Tab
				const isIsolated = e.detail.info.isolated;
				let nAllBets = e.detail.info.allBets;
				let allBets = [];
				for(let key in e.detail.info.allBets) {

					// must be validated
					if(allBets.length < 20) {
						if(isIsolated === false && checkIsolated(nAllBets[key].company_id, locIsolatedList)) {
							// 
							let AllBetsNumbers = nAllBets[key].numbers.split(",");
							AllBetsNumbers.pop();
							AllBetsNumbers = AllBetsNumbers.map((num_) => parseInt(num_, 10));
							nAllBets[key]['numbers'] = AllBetsNumbers.sort((a, b) => a - b);
							allBets.push(nAllBets[key]);
						} else {
							console.log("CompanyID:", parseInt(storage.get('companyId')),
								" . 1This ticket not shown! Ticket companyId is: ", nAllBets[key].company_id);
							// dev fix just for fun
							if(isIsolated === true &&
								devIsolated(nAllBets[key].company_id, parseInt(storage.get('companyId')))) {
								let AllBetsNumbers = nAllBets[key].numbers.split(",");
								AllBetsNumbers.pop();
								AllBetsNumbers = AllBetsNumbers.map((num_) => parseInt(num_, 10));
								nAllBets[key]['numbers'] = AllBetsNumbers.sort((a, b) => a - b);
								allBets.push(nAllBets[key]);
							}
						}
					}

				}

				if(allBets !== null) {
					setAllBetsItems(allBets);
					storage.set('limitAllBets', allBets.length)
				}

				// My Bets Tab
				let nMyBets = e.detail.info.myBets;
				let myBets = [];
				let myBetsActual = [];
				for(let key in e.detail.info.myBets) {
					// Handler becouse items can be with or without round_numbers
					if(typeof nMyBets[key].round_numbers === 'undefined' ||
						nMyBets[key].round_numbers === null) {
						let MyBetsNumbers = nMyBets[key].numbers.split(",");
						MyBetsNumbers.pop();
						MyBetsNumbers = MyBetsNumbers.map((num_) => parseInt(num_, 10));
						nMyBets[key]['numbers'] = MyBetsNumbers.sort((a, b) => a - b);
						myBetsActual.push(nMyBets[key]);
						setMyBetsItems(myBetsActual);
					} else {
						// limited on server (20)
						console.log('MyBets [round_numbers presents] => ');
						let MyBetsOldIndexArray = nMyBets[key].round_numbers.split(",");
						MyBetsOldIndexArray.pop();
						MyBetsOldIndexArray = MyBetsOldIndexArray.map((num_) => parseInt(num_, 10));
						nMyBets[key]['round_numbers'] = MyBetsOldIndexArray;
						// handle round numbers
						let MyBetsNumbers = nMyBets[key].numbers.split(",");
						MyBetsNumbers.pop();
						MyBetsNumbers = MyBetsNumbers.map((num_) => parseInt(num_, 10));
						nMyBets[key]['numbers'] = MyBetsNumbers.sort((a, b) => a - b);
						myBets.unshift(nMyBets[key]);
					}
				}

				setMyBetsItemsFromServer(myBets);
				storage.set("myBetsFromServerFlag", myBets.length);
			});

			window.addEventListener("playerInfo", (e) => {
				// console.log('PlatformID' , e.detail.info.platform_id)
				var isCustomLangSweepium = false;
				sessionStorage.setItem('platform_id', e.detail.info.platform_id);

				setCurrentBetAmount(e.detail.info.company_currency_data.default_bet);

				if(typeof e.detail.info.customization !== 'undefined' && e.detail.info.customization !== "") {
					try {
						var customization = JSON.parse(e.detail.info.customization)
						if(typeof customization !== 'undefined') {
							console.log('customization -> ', customization)
							// for any case
							if((typeof customization.fastBallsAutoPlay !== 'undefined' && customization.fastBallsAutoPlay === false) ||
								(typeof customization.FastBallsAutoPlay !== 'undefined' && customization.FastBallsAutoPlay === false)) {
								// console.log('<customization> ', customization)
								if(typeof customization.fastBallsAutoPlay !== 'undefined') storage.set('fastBallsAutoPlay', customization.fastBallsAutoPlay);
								if(typeof customization.FastBallsAutoPlay !== 'undefined') storage.set('fastBallsAutoPlay', customization.FastBallsAutoPlay);
								document.getElementById('playBtnId').classList.remove('place-bet-button-holder-long');
								document.getElementById('playBtnId').classList.add('place-bet-button-holder-full');
								document.getElementById('autoPlayBtnId').style.display = 'none'
							} else {
								storage.set('fastBallsAutoPlay', true);
							}
							if(customization.maxAutoPlayGames) {
								sessionStorage.setItem('maxAutoPlayGames', Number(customization.maxAutoPlayGames));
							} else {
								sessionStorage.setItem('maxAutoPlayGames', 100);
							}
							// Format
							if(typeof customization.amountFormat !== 'undefined') {
								//
								console.log('<customization customization.amountFormat DEFINED> ', customization.amountFormat)
								sessionStorage.setItem('amountFormat', customization.amountFormat);
							} else {
								// amountFormat
								// console.log('<NO amountFormat>!!')
								sessionStorage.setItem('amountFormat', null);
							}

							if(typeof customization.customLang !== 'undefined') {
								if(customization.customLang === "sweepium") {
									isCustomLangSweepium = true;
								}
							}

							if(typeof customization.fixedBet !== 'undefined') {
								sessionStorage.setItem('fixedBet', customization.fixedBet)
								var fixPreferedBtnsValue = sessionStorage.getItem('fixedBet').split('|');
								setCurrentBetAmount(fixPreferedBtnsValue[0]);
			          storage.set('currentBetAmount', fixPreferedBtnsValue[0]);
							}

						}
					} catch(err) {
						sessionStorage.setItem('maxAutoPlayGames', 100);
					}

				} else {
					sessionStorage.setItem('maxAutoPlayGames', 100);
					storage.set('fastBallsAutoPlay', true);
				}

				props.setPromoBalance(e.detail.info.promobalance);
				setMaxWin(e.detail.info.company_currency_data.max_win);
				setMinBetPerComb(e.detail.info.company_currency_data.min_bet_combinations);
				setMinBetAmount(e.detail.info.company_currency_data.min_bet);
				props.setCheckMinBet(e.detail.info.company_currency_data.min_bet);
				setMaxBetAmount(e.detail.info.company_currency_data.max_bet);
				setPrefinedBets(e.detail.info.company_currency_data.prefined_bets);
				if(e.detail.info.country_code == null) {
					console.log('Special:case:cc:null')
					setCountryCode(e.detail.info.country_code);
					storage.set('flagPreview', false);
				} else {
					// console.log('cc is not null')
					setCountryCode(e.detail.info.country_code);
					storage.set('flagPreview', true);
				}

				if(e.detail.info.currency_code === 'ETBB') {
					setCurrencyCode('ETB');
					storage.set('currencyCode', 'ETB');
					storage.set('userCurrency', 'ETB');
				} else if(e.detail.info.currency_code === 'GCS' && isCustomLangSweepium === true) {
					console.log('  GC -> ')
					setCurrencyCode('GC');
					storage.set('currencyCode', 'GC');
					storage.set('userCurrency', 'GC');
				} else if(e.detail.info.currency_code === 'FCS' && isCustomLangSweepium === true) {
					console.log('  FC -> ')
					setCurrencyCode('FC');
					storage.set('currencyCode', 'FC');
					storage.set('userCurrency', 'FC');
				} else {
					setCurrencyCode(e.detail.info.currency_code);
					storage.set('currencyCode', e.detail.info.currency_code);
					storage.set('userCurrency', e.detail.info.currency_code);
				}

				setUsername(e.detail.info.username);
				storage.set('maxWin', e.detail.info.company_currency_data.max_win);
				storage.set('serverUsername', e.detail.info.username);
				storage.set('minBetAmount', e.detail.info.company_currency_data.min_bet);
				storage.set('currentBetAmount', e.detail.info.company_currency_data.default_bet);
				storage.set('initialCurrentBetAmount', e.detail.info.company_currency_data.default_bet);
				props.setBalance(e.detail.info.balance);
				props.setOptionMusic(e.detail.info.music_enabled === 1 ? true : false);
				props.setOptionSound(e.detail.info.sound_enabled === 1 ? true : false);
				storage.set('music', e.detail.info.music_enabled === 1 ? true : false);

				// Show promo popup on login
				let testPromoLocal = parseFloat(e.detail.info.promobalance);
				if(testPromoLocal >= e.detail.info.company_currency_data.min_bet) {
					const r = "" + e.detail.info.promobalance + " " + e.detail.info.currency_code;
					let notify = new CustomEvent('on-direct-message', {
						detail: {"type": "PROMOCREDIT", "msg": r}
					});
					dispatchEvent(notify);
				}
			});

			window.addEventListener("historyInfo", (e) => {
				let historyObjects = [];
				e.detail.info.forEach((element, index) => {
					let test = element.datetime;
					let locTime = test.split(" ")[1].split(".")[0];
					let numbers = element.numbers.split(",");
					numbers.pop();
					let local = {
						roundId: element.id,
						date: test.split(" ")[0],
						time: locTime,
						numbers: numbers,
					};
					historyObjects.push(local);
				});
				setHistoryData(historyObjects);
			});

			window.addEventListener("tickBets", (e) => {
				// All Bets Tab
				let nAllBets = e.detail.info;
				let collectAll = [];
				// Max limit param
				const MAX_ALLBET = 30;
				const isIsolated = storage.get('isolated');
				const isList = storage.get('isolatedList');

				if(storage.get('limitAllBets') < MAX_ALLBET) {
					for(let key in e.detail.info) {

						if(collectAll.length < MAX_ALLBET) {
							if(isIsolated === false && checkIsolated(nAllBets[key].company_id, isList)) {
								let AllBetsNumbers = nAllBets[key].numbers.split(",");
								// fix for any case
								// 1,2,3,4,5,6,  origin , 1,2,3,4,5,6 mock
								if(AllBetsNumbers[AllBetsNumbers.length - 1] === ',' ||
									AllBetsNumbers[AllBetsNumbers.length - 1] === '') {
									AllBetsNumbers.pop();
								}
								AllBetsNumbers = AllBetsNumbers.map((num_) => parseInt(num_, 10));
								nAllBets[key]['numbers'] = AllBetsNumbers.sort((a, b) => a - b);
								collectAll.push(nAllBets[key]);
							} else {
								// console.log("Global CompanyID:" , parseInt(storage.get('companyId')),
								// " . This ticket not shown! Ticket companyId is: ", nAllBets[key].company_id);
								// dev fix just for fun
								if(isIsolated === true &&
									devIsolated(nAllBets[key].company_id, parseInt(storage.get('companyId')))) {
									let AllBetsNumbers = nAllBets[key].numbers.split(",");
									if(AllBetsNumbers[AllBetsNumbers.length - 1] === ',' ||
										AllBetsNumbers[AllBetsNumbers.length - 1] === '') {
										AllBetsNumbers.pop();
									}
									AllBetsNumbers = AllBetsNumbers.map((num_) => parseInt(num_, 10));
									nAllBets[key]['numbers'] = AllBetsNumbers.sort((a, b) => a - b);
									collectAll.push(nAllBets[key]);
									// console.log("Isolated company!");
								}
							}
						}
					}

					setAllBetsItems((prevState) => {
						let t = [...prevState];
						if(t.length < MAX_ALLBET) {
							collectAll.forEach((i) => {
								t.push(i)
							})
						}
						storage.set('limitAllBets', t.length)
						return [...t];
					});
				}
			});

			window.addEventListener("topwins-update", (e) => {
				setTopWinsItems(e.detail)
			});

			window.addEventListener("topodds-update", (e) => {
				setTopOddsItems(e.detail)
			});

			setTimeout(() => {
				showLoadingScreen(false);
			}, 1500);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ballInterval, drawnNumbers, drawnNumbers.length, isAppEventAttached, props.net.avatarId]);

	useEffect(() => {
		const onBetAck = (e) => {
			const betAck = e.detail.info.bet_ack;
			if(betAck.bet_passed === true) {
				props.setBalance(betAck.balance);
				props.setPromoBalance(betAck.promobalance);

				const myBetAck = {
					status: "OK",
					balance: betAck.balance,
					roundId: betAck.round_id,
					ticketId: betAck.ticket_id,
					ticketType: betAck.ticket_type,
				};
				setBetAckTicketData(myBetAck);
				storage.set("betAckTicketData", JSON.stringify(myBetAck));

				let AllBetsNumbers = betAck.numbers.split(",");
				// fix for any case
				// 1,2,3,4,5,6,  origin , 1,2,3,4,5,6 mock
				if(AllBetsNumbers[AllBetsNumbers.length - 1] === ',' ||
					AllBetsNumbers[AllBetsNumbers.length - 1] === '') {
					AllBetsNumbers.pop();
				}
				AllBetsNumbers = AllBetsNumbers.map((num_) => parseInt(num_, 10));
				betAck.numbers = AllBetsNumbers.sort((a, b) => a - b);

				let betValidated = new CustomEvent("place-bet-validated",
					{
						detail: {
							status: 'validated',
							ticketNumbers: betAck.numbers
						}
					});
				dispatchEvent(betValidated);

				storage.set('avoid', true);
				setMyBetsItems((prevState) => {
					let t = [...prevState];
					// Max limit param for myBets initial data
					const MAX_MYBET = 20;
					if(t.length + parseFloat(storage.get("myBetsFromServerFlag")) > MAX_MYBET) {
						if(parseFloat(storage.get("myBetsFromServerFlag")) > 0) {
							setMyBetsItemsFromServer((prevState) => {
								let test = [...prevState];
								if(test.length > 0) {
									test.shift();
								} else {
									return [...test];
								}
								// Every shift will be saved.
								storage.set("myBetsFromServerFlag", test.length);
								return [...test];
							})
						} else {
							// Clear my self
							t.shift();
						}
					}
					t.push(betAck);
					return [...t];
				});

				storage.set('preventDC', null);
			} else {
				console.log('BET ACK NOTHIING ! no prevent any more ! ')
				storage.set('preventDC', null);
				toast.info(
					() => {
						return (
							<>
								FastBalls:
								<br />
								Bet not passed
								<br />
							</>
						);
					},
					{
						toastId: 'no-duplicate',
						theme: "dark",
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						icon: false,
					}
				);
			}
		};

		const getAnimationDeltaTime = (e) => {
			storage.set("animation_time", e.detail.info.anim_interval);
			window.removeEventListener(
				"getAnimationDeltaTime",
				getAnimationDeltaTime
			);
		};

		/**
		 * @description
		 *  GameStatus 0
		 */
		const getGameStatus = (e) => {
			if(e.detail.info.rs === 1) {
				// console.log('STATUS 1')

				if(e.detail.info.numbers === null) {
					console.warn("Rs is 1 but numbers is null");
					return;
				}

				var b = e.detail.info.bonus;
				if(b === 1 && storage.get("tickDeterminator") > 0) {
					soundEffect.play('on_bonus');
				}
				setBonus(b);

				// create syntetic tick counter based on change game status
				storage.set("tickDeterminator", 0)

				let testlocal = JSON.parse(storage.get('last_win_balls'));
				if(testlocal !== null && testlocal?.length === 35 &&
					e.detail.info.rnd !== storage.get('roundId')) {
					// History
					const localdateTime = new Date();
					const month = ((localdateTime.getMonth() + 1) < 10 ? "0" + (localdateTime.getMonth() + 1) : (localdateTime.getMonth() + 1));
					const day = (localdateTime.getDay() < 10 ? "0" + localdateTime.getDay() : localdateTime.getDay());
					const hours = (localdateTime.getHours() < 10 ? "0" + localdateTime.getHours() : localdateTime.getHours());
					const min = (localdateTime.getMinutes() < 10 ? "0" + localdateTime.getMinutes() : localdateTime.getMinutes());
					const sec = (localdateTime.getSeconds() < 10 ? "0" + localdateTime.getHours() : localdateTime.getSeconds());
					let local = {
						roundId: storage.get('roundId'),
						date: localdateTime.getFullYear() + "-" + month + "-" + day,
						time: hours + ":" + min + ":" + sec,
						numbers: testlocal,
					};
					setHistoryData((prevState) => {
						let old = [...prevState];
						old.pop();
						old.unshift(local);
						return old;
					});
					storage.remove('last_win_balls');
				}

				setPercentage(0);
				if(roundID === 0) {
					setRoundID(e.detail.info.rnd);
				}

				let numbers = e.detail.info.numbers.split(",");
				numbers.pop();
				setDrawnNumbers(numbers);
				storage.set('last_win_balls', JSON.stringify(numbers))
				let test1 = parseFloat(e.detail.info.next_rs) * 1100;
				let localDelta = parseFloat((test1 / ballInterval).toFixed(0));
				let test = 35 - localDelta <= 0 ? 0 : 35 - localDelta;
				if(test <= 13) {
					test *= 0.2;
				}
				if(test > 13) {
					test *= 1.3;
				}
				test = parseFloat(test.toFixed(0));
				if(mustBeDraw === 0 && test > 0) {
					setMustBeDraw(test);
				} else {
					setBallInterval(e.detail.info.anim_interval);
					setMustBeDraw(0);
				}
				setGameStatus(1);

			} else {
				/**
				 * @description
				 * GameStatus 0
				 * next_rs is not usefull except first initial value.
				 * Because values are type of float also can be negative < 0,
				 * sometime can be 3 , 4 or 5 tick.
				 * Better variant is `tickDeterminator` wich is (gamestatus=0) tick counter.
				 */
				// console.log('NEXT ROUND IN = ' , e.detail.info.next_rs)
				storage.set('atSoundPlayed', false);
				var betAct = JSON.parse(storage.get("betAckTicketData"));

				// Only once
				if(storage.get('autoplay') === true && e.detail.info.rnd > parseInt(betAct?.roundId)) {
					// Set autoplay round counter
					let test = storage.get('autoPlayCurrentRound');
					test = parseInt(test);
					storage.set('autoPlayCurrentRound', (test + 1));
					setAutoPlayUpdateMe(Date.now());
				}

				setGameStatus(0);
				// Steps - Step 1
				if(parseFloat(storage.get('tickDeterminator')) === 0) {

					// console.info(' BIG RESET ALTERNATIVE TEST= > ', e.detail.info.bonus);
					let b = e.detail.info.bonus;
					setBonus(b);

					storage.set('tickDeterminator', 1);
					storage.set('limitAllBets', 0);
					setAllBetsItems((prevState) => {
						let t = [...prevState];
						let handlerIndex = [];
						let lastByRoundId = [];
						for(var j = 0;j < t.length;j++) {
							if(t[j].round_id < e.detail.info.rnd) {
								handlerIndex.push(j);
							}
						}
						for(var i = handlerIndex.length - 1;i >= 0;i--) {
							let test = t.splice(i, 1);
							lastByRoundId.push(test[0]);
						}
						if(t.length === 0) {
							// If got empty clear reser previus
							setLocalRecordsPrevius([]);
							setAllBetsPreviousHand([...prevState]);
						} else {
							setLocalRecordsPrevius([]);
							setAllBetsPreviousHand([...lastByRoundId]);
						}
						return [...t]
					});

					setPercentage(parseInt(e.detail.info.next_rs));

					dispatchEvent(new CustomEvent("update-jackpots", {detail: {}}));

				} else if(parseFloat(storage.get('tickDeterminator')) === 1) {
					// Step 2
					storage.set('tickDeterminator', 2);

					setIndexArray([]);
					setIndexArrayQuota([]);

					setPercentage(parseInt(e.detail.info.next_rs));
					setBounce(true);
					setTimeout(function() {
						setBounce(false)
					}, 500);

					soundEffect.play('countdown');

					dispatchEvent(new CustomEvent("update-balance", {detail: props.balance}));
					dispatchEvent(new CustomEvent("update-bonus-history", {detail: {}}));

					setNextRs(parseInt(e.detail.info.next_rs));
					setMustBeDraw(0);
					setBallInterval(e.detail.info.anim_interval);
					setDrawnNumbers([]);

				} else if(parseFloat(storage.get('tickDeterminator')) === 2) {
					// Step 3
					storage.set('tickDeterminator', 3);
					setPercentage(parseInt(e.detail.info.next_rs));
					setBounce(true);
					setTimeout(function() {
						setBounce(false);
					}, 500);

					soundEffect.play('countdown');
					setNextRs(parseInt(e.detail.info.next_rs));
					setMustBeDraw(0);
					setBallInterval(e.detail.info.anim_interval);
					setDrawnNumbers([]);
					setRoundID(e.detail.info.rnd);
					storage.set('roundId', e.detail.info.rnd);

				} else if(parseFloat(storage.get('tickDeterminator')) === 3) {
					// Step 4
					setPercentage(parseInt(e.detail.info.next_rs));
					setBounce(true);
					setTimeout(function() {
						setBounce(false);
					}, 500);
					soundEffect.play('countdown');
					storage.set('tickDeterminator', 4);
				} else if(parseFloat(storage.get('tickDeterminator')) === 4) {
					// Step 5 - Sometime no exist
					setPercentage(parseInt(e.detail.info.next_rs));
					setBounce(true);
					setTimeout(function() {
						setBounce(false)
					}, 500);
					soundEffect.play('countdown');
				}

				if(e.detail.info.rnd < betAct?.roundId) {
					console.log("ROUND ID IS FUTURE!", betAct)
				} else if(e.detail.info.rnd === betAct?.roundId) {
					// console.log("ROUND ID ARE SAME!", betAct)
				} else if(e.detail.info.rnd > parseInt(betAct?.roundId)) {
					// console.log("ROUND ID ENDED - ONLY ONES!", betAct)
					// CLEAR Only once
					setActiveTicketCurrentBet(0);
					setBetAckTicketData({});
					setActiveTicketNumbers([]);
					setBetStatus(0);
					storage.remove("betAckTicketData");

					setActiveTicketEmitedWin(0);
					// AutoPlay
					if(storage.get('autoplay') === true) {
						// console.log("POWER AUTO PLAY! -> storage.get('currentBetAmount') ", storage.get('currentBetAmount'));
						dispatchEvent(new CustomEvent("place-bet-autoplay",
							{
								detail: {
									ticketNumbers: storage.get("ticketNumbers"),
									sid: storage.get("sid"),
									minBetAmount: storage.get('minBetAmount'),
									currentBetAmount: storage.get('currentBetAmount')
								}
							}));
					}
				}
			}
		};

		/**
		 * @description
		 * Make stronger balance update becouse async response
		 */
		const updateBalanceIfEqual = (e, data) => {
			setTimeout(() => {
				data.detail.self.requestUserBalance();
			}, 1000);
			setTimeout(() => {
				props.setBalance((pre) => {
					if(e === pre) {
						setTimeout(() => {
							data.detail.self.requestUserBalance();
						}, 1000);
					} // else { console.log('BALANCE IS GOOD') }
					return pre;
				});
			}, 2000);
		};

		if(isAppEventAttached2 === false) {
			setIsAppEventAttached2(true);
			window.addEventListener("betAck", onBetAck);
			window.addEventListener("gameStateInfo", getGameStatus);
			window.addEventListener("getAnimationDeltaTime", getAnimationDeltaTime);

			// optimisation
			window.addEventListener("optActiveTicket", (e) => {
				setActiveTicketEmitedWin(e.detail.myTicketSum);
			});

			window.addEventListener("cancel-bet", (data) => {
				// previus balance
				let currentBalance = 0;
				props.setBalance((pre) => {
					currentBalance = pre;
					return pre;
				});

				// not valid always from server at this moment
				updateBalanceIfEqual(currentBalance, data);

				setActiveTicketNumbers([]);
				setBetAckTicketData({});
				storage.remove('betAckTicketData');
				setBetStatus(0);
				setActiveTicketCurrentBet(0);
				// clear myBets tab
				setMyBetsItems((prevState) => {
					let t = [...prevState];
					let detIndex = 0;
					t.forEach((e, index) => {
						if(e.ticket_id === data.detail.info.ticket_id) {
							detIndex = index;
						}
					});
					t.splice(detIndex, 1)
					return [...t]
				});
				// clear allBets tab
				setAllBetsItems((prevState) => {
					let t = [...prevState];
					let detIndex = 0;
					t.forEach((e, index) => {
						if(e.ticket_id === data.detail.info.ticket_id) {
							detIndex = index;
						}
					});
					t.splice(detIndex, 1)
					return [...t]
				});
			});

			window.addEventListener("cancel-all-bet", (data) => {
				// clear allBets tab
				// clear myBets tab
				setAllBetsItems((prevState) => {
					let t = [...prevState];
					let detIndex = 0;
					t.forEach((e, index) => {
						if(e.ticket_id === data.detail) {
							detIndex = index;
						}
					});
					t.splice(detIndex, 1)
					return [...t]
				});
			});

			window.addEventListener("onCurrentDrawnNumber", (data) => {
				try {
					if(props.activeTicketNumbers?.sort((a, b) => a - b).indexOf(data.detail.number) !== -1) {
						setIndexArray((oldArray) => {
							return [...oldArray, data.detail.number];
						});
						setIndexArrayQuota((oldArray) => [...oldArray, data.detail.quota])
					}
					soundEffect.play('num_appear');
				} catch(err) {}
			});

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoPlay, ballInterval, drawnNumbers, drawnNumbers.length, indexArray, isAppEventAttached, isAppEventAttached2, mustBeDraw, props.net]);

	return (
		<div
			className={
				"game-holder " +
				((isMobile()) && autoPlayRounds > 0
					? "game-holder-active-auto-play"
					: "")
			}
			style={(activeTab === "results" && isMobile() ? {paddingBottom: 0, height: "calc(100% - 55px)"} : {})}
		>
			{loadingScreen ? <LoadingScreen /> : null}
			<div className="container">
				{useMemo(() =>
					isMobile() === false ? <>
						<div className="game-holder-left" style={(isMobile() === false ? {display: "block"} : {display: "none"})}>
							<Tab.Container id="left-tabs-example" defaultActiveKey="first">
								<Nav className="game-holder-tabs">
									<Nav.Item>
										<Nav.Link className="noselect" eventKey="first" onClick={() => {soundEffect.play('tabs')}}>
											{label.t("game")}
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className="noselect" eventKey="second" onClick={() => {
											soundEffect.play('tabs');
											props.net.updateNewMsg = 0;
										}}>
											{label.t("chat_room")}
											<NewMsgNotify hide={(props.net.updateNewMsg === 0 ? true : false)} label={props.net.updateNewMsg} updateNewMsg={props.net.updateNewMsg} />
										</Nav.Link>
									</Nav.Item>
								</Nav>
								<Tab.Content>
									<Tab.Pane eventKey="first" onClick={() => {soundEffect.play('tabs')}}>
										<Results
											net={props.net}
											gameStatus={gameStatus}
											historyData={historyData}
											allBetsItems={allBetsItems}
											allBetsPreviousHand={allBetsPreviousHand}
											setLocalRecordsPrevius={setLocalRecordsPrevius}
											localRecordsPrevius={localRecordsPrevius}
											myBetsItems={myBetsItems}
											myBetsItemsFromServer={myBetsItemsFromServer}
											topWinsItems={topWinsItems}
											topOddsItems={topOddsItems}
											indexArray={indexArray}
											indexArrayQuota={indexArrayQuota}
											username={username}
											countryCode={countryCode}
											roundID={roundID}
											userCurrencyCode={storage.get('userCurrency')}
											pBonusHistory={props.net.pBonusHistory}
											activeTicketEmitedWin={activeTicketEmitedWin} />
									</Tab.Pane>
									<Tab.Pane mountOnEnter unmountOnExit eventKey="second" onClick={() => {soundEffect.play('tabs')}}>
										<Chat net={props.net} />
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</div>
					</>
						: <>
							<div className="game-holder-mobile" style={(activeTab === "results" && isMobile() ? {display: "block"} : {display: "none"})} >
								<Results
									net={props.net}
									gameStatus={gameStatus}
									historyData={historyData}
									allBetsItems={allBetsItems}
									allBetsPreviousHand={allBetsPreviousHand}
									setLocalRecordsPrevius={setLocalRecordsPrevius}
									localRecordsPrevius={localRecordsPrevius}
									myBetsItems={myBetsItems}
									myBetsItemsFromServer={myBetsItemsFromServer}
									topWinsItems={topWinsItems}
									topOddsItems={topOddsItems}
									indexArray={indexArray}
									indexArrayQuota={indexArrayQuota}
									username={username}
									countryCode={countryCode}
									roundID={roundID}
									userCurrencyCode={currencyCode}
									pBonusHistory={props.net.pBonusHistory}
									activeTicketEmitedWin={activeTicketEmitedWin} />
							</div>
						</>
					,
					[activeTicketEmitedWin, activeTab, allBetsItems, allBetsPreviousHand, countryCode, currencyCode,
						gameStatus, historyData, indexArray, indexArrayQuota, localRecordsPrevius,
						myBetsItems, myBetsItemsFromServer, props.net, roundID, topOddsItems, topWinsItems, username]
				)}

				<div style={{display: (activeTab === "chat" || activeTab === "results" ? "none" : null)}}
					className="game-holder-right">
					{useMemo(() => <LiveGame
						tab={(activeTab !== "bets" ? {visible: "none"} : null)}
						net={props.net}
						userCurrencyCode={currencyCode}
						bonus={bonus}
						nextRs={nextRs}
						percentage={percentage}
						setPercentage={setPercentage}
						roundID={roundID}
						setBallInterval={setBallInterval}
						ballInterval={ballInterval}
						gameStatus={gameStatus}
						drawnNumbers={drawnNumbers}
						mustBeDraw={mustBeDraw}
						JP1MenuVisibility={props.JP1MenuVisibility}
						setJP1MenuVisibility={props.setJP1MenuVisibility}
						JP2MenuVisibility={props.JP2MenuVisibility}
						setJP2MenuVisibility={props.setJP2MenuVisibility}
						JP3MenuVisibility={props.JP3MenuVisibility}
						setJP3MenuVisibility={props.setJP3MenuVisibility}
						JP1History={props.net.JP1History}
						JP2History={props.net.JP2History}
						JP3History={props.net.JP3History}
						bounce={bounce}
					/>
						,
						[activeTab, props.net, props.JP1MenuVisibility, props.setJP1MenuVisibility, props.JP2MenuVisibility, props.setJP2MenuVisibility, props.JP3MenuVisibility, props.setJP3MenuVisibility, currencyCode, bonus, nextRs, percentage, roundID, ballInterval, gameStatus, drawnNumbers, mustBeDraw, bounce]
					)}

					<div className="bottom-game-holder">
						{useMemo(
							() =>
								<ChooseNumbers
									tab={(activeTab !== "bets" ||
										(isMobile() && activeTicketNumbers.length > 0) ? {visible: "none"} : {visible: "block"})}
									setTicketNumbers={setTicketNumbers}
									ticketNumbers={ticketNumbers}
									betStatus={betStatus}
									clearChoosenNumbers={clearChoosenNumbers}
								/>
							,
							// don't remove width
							// eslint-disable-next-line react-hooks/exhaustive-deps
							[activeTab, activeTicketNumbers.length, ticketNumbers, betStatus, clearChoosenNumbers, width]
						)}
						<div className="bottom-game-holder-ticket">
							<div
								className={
									"ticket-position " +
									((isMobile()) &&
										(betStatus === 1)
										? "active-game"
										: "")
								}
							>
								<>
									<ActiveTicket
										autoPlayUpdateMe={autoPlayUpdateMe}
										width={width}
										tab={(activeTab !== "bets" || activeTicketNumbers.length < 6 ? {visible: "none"} : null)}
										net={props.net}
										roundID={roundID}
										betAckTicketData={betAckTicketData}
										indexArray={indexArray}
										indexArrayQuota={indexArrayQuota}
										autoPlayRounds={autoPlayRounds}
										activeTicketNumbers={activeTicketNumbers}
										currentBetAmount={currentBetAmount}
										activeTicketCurrentBet={activeTicketCurrentBet}
										setAutoPlayRounds={setAutoPlayRounds}
										setAutoPlay={setAutoPlay}
										gameStatus={gameStatus}
										betStatus={betStatus}
										userCurrencyCode={currencyCode}
										promoBalance={props.promoBalance}
										activeTicketEmitedWin={activeTicketEmitedWin}
									/>
									<YourTicket
										tab={(activeTab !== "bets" || activeTicketNumbers.length > 0 ? {visible: "none"} : null)}
										autoPlayRounds={autoPlayRounds}
										ticketNumbers={ticketNumbers}
										setTicketNumbers={setTicketNumbers}
										gameStatus={gameStatus}
										currentBetAmount={currentBetAmount}
										setCurrentBetAmount={setCurrentBetAmount}
										minBetAmount={minBetAmount}
										minBetPerComb={minBetPerComb}
										maxWin={maxWin}
										userCurrencyCode={currencyCode}
									/>
								</>

								{useMemo(
									() =>
										(isMobile()) && autoPlay ? (
											<div className="quit-autoplay-mobile">
												<SecondaryButton
													onClick={() => {
														setAutoPlayRounds(0);
														// autoplay test
														console.log(" storage.set('autoPlayCurrentRound', 0); ")
														storage.set('autoPlayCurrentRound', 0);
														setAutoPlay(false);
														soundEffect.play('decrease_amount_BTN');
													}}
													label={label.t("cancel_autoplay")}
												/>
											</div>
										) : null,
									[autoPlay]
								)}
							</div>
							{useMemo(
								() =>
									<ChooseAmount
										tab={((activeTab !== "bets" ||
											(isMobile() && activeTicketNumbers.length > 0)) ? {visible: "none"} : {visible: "block"})}
										sid={sid}
										net={props.net}
										roundID={roundID}
										currencyCode={currencyCode}
										prefinedBets={prefinedBets}
										ticketNumbers={
											clearChoosenNumbers === false ? ticketNumbers : []
										}
										setActiveTicketNumbers={setActiveTicketNumbers}
										setClearChoosenNumbers={setClearChoosenNumbers}
										setAutoPlayShow={setAutoPlayShow}
										setBetStatus={setBetStatus}
										gameStatus={gameStatus}
										betStatus={betStatus}
										autoPlayRounds={autoPlayRounds}
										setAutoPlayRounds={setAutoPlayRounds}
										currentBetAmount={currentBetAmount}
										setCurrentBetAmount={setCurrentBetAmount}
										minBetPerComb={minBetPerComb}
										minBetAmount={minBetAmount}
										maxBetAmount={maxBetAmount}
										setAutoPlay={setAutoPlay}
										autoPlay={autoPlay}
										balance={props.balance}
										promoBalance={props.promoBalance}
									/>
								,
								// eslint-disable-next-line react-hooks/exhaustive-deps
								[width, roundID, activeTab, activeTicketNumbers.length, sid, props.net, props.balance, currencyCode, prefinedBets, clearChoosenNumbers, ticketNumbers, gameStatus, betStatus, autoPlayRounds, currentBetAmount, minBetAmount, maxBetAmount, autoPlay]
							)}
						</div>
					</div>
					{useMemo(
						() =>
							autoPlayShow ? (
								<AutoplayPopUp
									setActiveTicketNumbers={setActiveTicketNumbers}
									minBetAmount={minBetAmount}
									currentBetAmount={currentBetAmount}
									sid={sid}
									ticketNumbers={ticketNumbers}
									setAutoPlayRounds={setAutoPlayRounds}
									setAutoPlayShow={setAutoPlayShow}
									setBetStatus={setBetStatus}
									setAutoPlay={setAutoPlay}
								/>
							) : null,
						[autoPlayShow, currentBetAmount, minBetAmount, sid, ticketNumbers]
					)}

				</div>

				{(activeTab === 'chat' ?
					<Chat net={props.net} /> : null
				)}
			</div>

			{isMobile() ? <BottomNavigator net={props.net} activeTab={activeTab} setActiveTab={setActiveTab} /> : null}

			{(!props.isNicknameAdded && !loadingScreen) || props.showUsernameComp ?
				<Username
					setShowUsernameComp={props.setShowUsernameComp}
					setIsNicknameAdded={props.setIsNicknameAdded}
					isNicknameAdded={props.isNicknameAdded}
					changeUsername={props.net.changeUsername} /> : null}

			{useMemo(
				() =>
					<ChangeAvatar
						style={{display: ((props.showChangeAvatar && !loadingScreen) ? 'flex' : 'none')}}
						setShowChangeAvatar={props.setShowChangeAvatar}
						avatarId={props.net.avatarId}
						net={props.net} />,
				[loadingScreen, props.net, props.setShowChangeAvatar, props.showChangeAvatar])}
		</div>
	);
}
