import * as React from "react";

const ChooseNumbersSvg = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Zm0 4a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm0 1.75a2.5 2.5 0 0 1 1.88 4.148c.565.456.92 1.117.92 1.852 0 1.38-1.254 2.5-2.8 2.5-1.546 0-2.8-1.12-2.8-2.5 0-.735.355-1.396.92-1.853A2.5 2.5 0 0 1 10 5.75Zm0 5c-.753 0-1.3.488-1.3 1s.547 1 1.3 1 1.3-.488 1.3-1-.547-1-1.3-1Zm0-3.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      fill="#1070FF"
    />
  </svg>
);

export default ChooseNumbersSvg;
