import CurrentRoundResults from "../CurrentRoundResults/CurrentRoundResults";
import LotteryDrum from "../LotteryDrum/LotteryDrum";
import LiveGameSvg from "../svg/live-game-svg";
import label from "../../services/Translation/lang";
import { isMobile } from "../../services/Helpers/checkPlatform";
import { useMemo } from "react";
import Jackpot from "../Jackpot/Jackpot";
import storage from "../../services/Storage/Storage";

export default function LiveGame(props) {
  return (
    <div
      style={{ display: props?.tab?.visible }}
      className={
        props.bonus === 0 ? "live-game " : "live-game bonus-live"
      }
    >

      {parseInt(storage.get('companyId')) === 1 ? (
        <div className="demo-badge">
          <div className="sticker">DEMO</div>
        </div>
      ) : null}

      <div className="live-game-background"></div>

      <div className="game-header-live">
        <div className="d-flex">
          <LiveGameSvg className="live-game-icon" />{" "}
          <span className="game-header-live-title">{ props.bonus === 0 ? label.t("live_game") : label.t("all_winsx2") }</span>
        </div>
        <div
          className={
            props.bonus === 0 ? "game-header-text" : "game-header-text"
          }
        >
          #{props.roundID}
        </div>
      </div>
      <div className="d-flex-live">
        <Jackpot
          net={props.net}
          JPUpdate={props.net.JPUpdate}
          JP1History={props.JP1History}
          JP2History={props.JP2History}
          JP3History={props.JP3History}
          JP1MenuVisibility={props.JP1MenuVisibility}
          setJP1MenuVisibility={props.setJP1MenuVisibility}
          JP2MenuVisibility={props.JP2MenuVisibility}
          setJP2MenuVisibility={props.setJP2MenuVisibility}
          JP3MenuVisibility={props.JP3MenuVisibility}
          setJP3MenuVisibility={props.setJP3MenuVisibility}
          userCurrencyCode={props.userCurrencyCode}
        />
      </div>
      <div className="live-game-body">
        {useMemo(
          () => (
            <LotteryDrum
              tab={props?.tab?.visible}
              nextRs={props.nextRs}
              setPercentage={props.setPercentage}
              percentage={props.percentage}
              mustBeDraw={props.mustBeDraw}
              setBallInterval={props.setBallInterval}
              ballInterval={props.ballInterval}
              gameStatus={props.gameStatus}
              drawnNumbers={props.drawnNumbers}
              bounce={props.bounce}
            />
          ),
          [ props.bounce,
            props.ballInterval,
            props.drawnNumbers,
            props.gameStatus,
            props.mustBeDraw,
            props.nextRs,
            props.percentage,
            props.setBallInterval,
            props.setPercentage,
            props?.tab?.visible,
          ]
        )}
        {useMemo(
          () =>
            isMobile() && props.gameStatus === 0 ? (
              <CurrentRoundResults
                hidden={true}
                bonus={props.bonus}
                gameStatus={props.gameStatus}
              />
            ) : (
              <CurrentRoundResults
                bonus={props.bonus}
                gameStatus={props.gameStatus}
              />
            ),
          [props.gameStatus, props.bonus]
        )}
      </div>
    </div>
  );
}
