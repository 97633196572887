
export function checkIsolated(ticketCompany, isolatedList) {
  let showTicket = true;
  isolatedList.forEach(i => {
    if (parseInt(i) === ticketCompany) {
      showTicket = false;
    }
  });
  return showTicket;
}

export function devIsolated(ticketCompany, companyId) {
  let showTicket = false;
    if (companyId === ticketCompany) {
      showTicket = true;
    }
  return showTicket;
}
