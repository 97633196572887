import React, {useEffect, useMemo, useState} from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import ChooseAmountSvg from "../svg/choose-amount-svg";
import ChooseAmountButton from "./ChooseAmountButton";
import label from "../../services/Translation/lang";
import calcComb from "../../services/Helpers/CalculateNumOfComb";
import storage from "../../services/Storage/Storage";
import soundEffect from "../../services/Sounds/SoundFxPlayer";
import {toast} from "react-toastify";
import {urlParams} from "../../services/UrlParamsLoader/UrlParamsLoader";
// import LocalStorage from "../../services/Storage/LocalStorage";

export default function ChooseAmount(props) {
	const [isAttachedEvent, setIsAttachedEvent] = useState(false);
	const [spamFilterPlaceBet, setSpamFilterPlaceBet] = useState(false);

	const showCancelBtn = () => {
		// promobalance in game - no cancel btn
		if(props.betStatus === 1 && props.promoBalance < props.minBetAmount) {
			if(props.gameStatus === 0 && storage.get('betAckTicketData') !== null &&
				JSON.parse(storage.get('betAckTicketData')).roundId === props.roundID) {
				return true;
			}
			if(props.gameStatus === 1 && storage.get('betAckTicketData') !== null &&
				JSON.parse(storage.get('betAckTicketData')).roundId !== storage.get('roundId')) {
				return true;
			}
		}
		return false;
	}

	const placeBetValidated = (e) => {
		/**
		 * @Note memo ticketNumbers every time when you call
		 * setActiveTicketNumbers.
		 */
		props.setActiveTicketNumbers(e.detail.ticketNumbers);
		storage.set("ticketNumbers", e.detail.ticketNumbers);
		storage.set("lastPlayedTicked", e.detail.ticketNumbers);
		props.setBetStatus(1);
	}

	const showNotify = () => {
		toast.error(() => {return <> {label.t('min_bet_amount_is')} {props.minBetAmount} </>}, {
			theme: 'dark',
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			onOpen: function() {
				if(typeof navigator.clipboard !== 'undefined') {
					navigator.clipboard.writeText(this.children.props.children[1]).then(function() {
						console.log('Copying to clipboard was successful!');
					}, function(err) {
						console.error('Could not copy text: ', err);
					});
				}
			}
		});
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	let placeBet = React.useCallback((e) => {
		// console.log(' PLACE BET ! storage.get(preventDC) ', storage.get('preventDC'))
		if(spamFilterPlaceBet === false && storage.get('preventDC') === null) {
			storage.set('preventDC', true);
			soundEffect.play('betBTN');
			// Get input arg if detail exist
			let ticketNumbers, sid, bet_amount, minBetAmount;
			if(e && e.type === "place-bet-autoplay") {
				ticketNumbers = e.detail.ticketNumbers;
				sid = e.detail.sid;
				bet_amount = e.detail.currentBetAmount;
				minBetAmount = e.detail.minBetAmount;
			} else {
				ticketNumbers = props.ticketNumbers;
				sid = props.sid;
				bet_amount = props.currentBetAmount;
				// eslint-disable-next-line no-unused-vars
				minBetAmount = props.minBetAmount;
			}

			// validation for bet_amount agains 
			if(ticketNumbers.length > 5) {
				const numOfComb = calcComb(6, ticketNumbers.length);
				if(bet_amount === "") {
					showNotify();
					return;
				} else if(numOfComb === 1) {
					if(props.minBetAmount > parseFloat(bet_amount)) {
						showNotify();
						return;
					}
				} else {
					if(props.minBetAmount > parseFloat(bet_amount)) {
						showNotify();
						return;
					}
				}
			}

			// looks like valid
			validationForDecimals();
			// Place bet action

			console.log('bet_amount', bet_amount)
			props.net.conn.emit("placed_bet", {
				sid: sid,
				bet_amount: bet_amount,
				bet_button_id: 1,
				numbers: ticketNumbers,
			});
		}
	})

	useEffect(() => {
		if(isAttachedEvent === false && typeof props.net.sound !== 'undefined') {
			setIsAttachedEvent(true);

			window.addEventListener("place-bet-autoplay", placeBet, false);
			window.addEventListener("place-bet-validated", placeBetValidated, false);
		}
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isAttachedEvent, placeBet, props.currentBetAmount, props.net])

	function validationForDecimals() {
		// validator for decimals
		let test = storage.get('currentBetAmount');
		if(sessionStorage.getItem('amountFormat') === '###,###') return;

		if(test !== null && props.net.noOfDecimals === 2) {
			let testD = test.toString().split('.');
			if(testD.length > 1 && testD[1].length === 1) {
				test = test + "0";
				props.setCurrentBetAmount(test);
				storage.set('currentBetAmount', test);
			}
			if(testD.length > 1 && testD[1].length === 0) {
				test = test + "00";
				props.setCurrentBetAmount(test);
				storage.set('currentBetAmount', test);
			}
			if(testD.length === 1) {
				test = test + ".00";
				props.setCurrentBetAmount(test);
				storage.set('currentBetAmount', test);
			}
		} else if(test !== null && props.net.noOfDecimals === 4) {
			let testD = test.toString().split('.');
			if(testD.length > 1 && testD[1].length === 3) {
				test = test + "0";
				props.setCurrentBetAmount(test);
				storage.set('currentBetAmount', test);
			}
			if(testD.length > 1 && testD[1].length === 2) {
				test = test + "00";
				props.setCurrentBetAmount(test);
				storage.set('currentBetAmount', test);
			}
			if(testD.length > 1 && testD[1].length === 1) {
				test = test + "000";
				props.setCurrentBetAmount(test);
				storage.set('currentBetAmount', test);
			}
			if(testD.length > 1 && testD[1].length === 0) {
				test = test + "0000";
				props.setCurrentBetAmount(test);
				storage.set('currentBetAmount', test);
			}
			if(testD.length === 1) {
				test = test + ".0000";
				props.setCurrentBetAmount(test);
				storage.set('currentBetAmount', test);
			}
		}
	}

	function incrementCount() {
		sessionStorage.setItem('preventDC', null);
		storage.set('dontCheckBackToMin', true);
		// upgrade 1.2.0
		// og-649 fixedBet increment logic
		if(sessionStorage.getItem('fixedBet') !== null) {
			var fixPreferedBtnsValue = sessionStorage.getItem('fixedBet').split('|');
			// props.setCurrentBetAmount(fixPreferedBtnsValue[0]);
			// storage.set('currentBetAmount', fixPreferedBtnsValue[0]);

			var i = fixPreferedBtnsValue.indexOf(props.currentBetAmount)
			if(i === fixPreferedBtnsValue.indexOf(fixPreferedBtnsValue[(fixPreferedBtnsValue.length - 1)])) {
				console.log('LOG  POSLEDNJI')
				return;
			}
			props.setCurrentBetAmount(fixPreferedBtnsValue[i + 1]);
			storage.set('currentBetAmount', fixPreferedBtnsValue[i + 1]);
			return;
		}
		// console.log('++++++', sessionStorage.getItem('fixedBet'))

		if(parseFloat(props.currentBetAmount) >= parseFloat(props.balance.toFixed(props.net.noOfDecimals)) ||
			parseFloat(props.currentBetAmount) >= parseFloat(props.promoBalance.toFixed(props.net.noOfDecimals))) {
			// case promo balance
			if(parseFloat(props.currentBetAmount) >= parseFloat(props.promoBalance.toFixed(props.net.noOfDecimals)) &&
				props.promoBalance >= props.minBetAmount) {
				var newValPromo = parseFloat(props.promoBalance.toFixed(props.net.noOfDecimals));
				props.setCurrentBetAmount(newValPromo);
				storage.set('currentBetAmount', newValPromo);
				soundEffect.play('increase_amount_BTN');
				// looks like valid
				validationForDecimals();
				return;
			} else if(props.promoBalance >= props.minBetAmount) {
				let local = parseFloat((parseFloat(props.currentBetAmount) + parseFloat(props.minBetAmount)).toFixed(props.net.noOfDecimals));
				props.setCurrentBetAmount(local);
				storage.set('currentBetAmount', local);
				soundEffect.play('increase_amount_BTN');
				// looks like valid
				validationForDecimals();
				return;
			}

			// case balance
			if(props.currentBetAmount >= parseFloat(props.balance.toFixed(props.net.noOfDecimals)) &&
				props.balance >= props.minBetAmount) {
				var newVal = parseFloat(props.balance.toFixed(props.net.noOfDecimals));
				props.setCurrentBetAmount(newVal);
				storage.set('currentBetAmount', newVal);
				soundEffect.play('increase_amount_BTN');
			} else if(props.balance >= props.minBetAmount) {
				let local = parseFloat((parseFloat(props.currentBetAmount) + parseFloat(props.minBetAmount)).toFixed(props.net.noOfDecimals));
				props.setCurrentBetAmount(local);
				storage.set('currentBetAmount', local);
				soundEffect.play('increase_amount_BTN');
			}

		} else if(parseFloat(props.currentBetAmount) < props.maxBetAmount) {
			let local = parseFloat((parseFloat(props.currentBetAmount) + parseFloat(props.minBetAmount)).toFixed(props.net.noOfDecimals));
			props.setCurrentBetAmount(local);
			storage.set('currentBetAmount', local);
			soundEffect.play('increase_amount_BTN');
		} else {
			props.setCurrentBetAmount(props.maxBetAmount);
			storage.set('currentBetAmount', props.maxBetAmount);
		}
		// looks like valid
		validationForDecimals();
	}

	function decrementCount() {
		sessionStorage.setItem('preventDC', null);
		storage.set('dontCheckBackToMin', true);

		// og-649 fixedBet increment logic
		console.log('LOG  POSLEDNJI sessionStorage.getItem(fixedBet) ', sessionStorage.getItem('fixedBet'))
		if(sessionStorage.getItem('fixedBet') !== null) {
			var fixPreferedBtnsValue = sessionStorage.getItem('fixedBet').split('|');
			props.setCurrentBetAmount(fixPreferedBtnsValue[0]);
			storage.set('currentBetAmount', fixPreferedBtnsValue[0]);
			var i = fixPreferedBtnsValue.indexOf(props.currentBetAmount)
			if(i === fixPreferedBtnsValue.indexOf(fixPreferedBtnsValue[0])) {
				console.log('LOG  POSLEDNJI MIN')
				return;
			}
			props.setCurrentBetAmount(fixPreferedBtnsValue[i - 1]);
			storage.set('currentBetAmount', fixPreferedBtnsValue[i - 1]);
			return;
		}

		if(props.currentBetAmount >= props.minBetAmount * 2) {
			let local = parseFloat((parseFloat(props.currentBetAmount) - parseFloat(props.minBetAmount)).toFixed(props.net.noOfDecimals));
			props.setCurrentBetAmount(local);
			storage.set('currentBetAmount', local);
			soundEffect.play('decrease_amount_BTN');
		} else {
			props.setCurrentBetAmount(props.minBetAmount);
			storage.set('currentBetAmount', props.minBetAmount);
			soundEffect.play('decrease_amount_BTN');
		}

		// looks like valid
		validationForDecimals();
	}

	return (
		<div style={{display: props?.tab?.visible}}
			className="choose-amount">
			<div className="game-header">
				<div className="d-flex">
					<ChooseAmountSvg className="live-game-icon" />
					{label.t("enter_amount")}
				</div>
			</div>
			<div className="choose-amount-body">
				<div className="choose-amount-input-holder">
					<button
						className="choose-amount-input-button minus"
						onClick={decrementCount}
						disabled={props.betStatus === 1 ? true : false}
					>
						-
					</button>
					<div id="buffer-text" style={{display: 'none'}}></div>
					<input
						contentEditable="true"
						id="cba"
						className="choose-amount-input"
						type="number"
						min={props.minBetAmount}
						max={props.maxBetAmount}
						value={props.currentBetAmount}
						onChange={(e) => {
							sessionStorage.setItem('preventDC', null);
							console.log('ON CHANGE !!!~! ', sessionStorage.getItem('preventDC'));
							let currentValue = e.target.value;
							let testD = e.currentTarget.value.split('.');
							console.log('ON CHANGE !!!~! ', currentValue);
							if(currentValue === "") {
								// console.log('ON CHANGE SPECIAL CASE NO CALC', currentValue);
								props.setCurrentBetAmount(currentValue);
								storage.set('currentBetAmount', currentValue);
								return;
							}
							if(testD.length > 1 && testD[1].length > 2) {
								// prevent more then two decimals
								let T = parseFloat(e.currentTarget.value).toFixed(props.net.noOfDecimals)
								props.setCurrentBetAmount(T);
								storage.set('currentBetAmount', T);
								// console.log('ON CHANGE SPECIAL CASE 2 NO CALC', T);
								return;
							}
							if(parseFloat(currentValue) < props.maxBetAmount && parseFloat(currentValue) <= props.balance &&
								parseFloat(currentValue) >= props.minBetAmount) {
								props.setCurrentBetAmount(currentValue);
								storage.set('currentBetAmount', currentValue);
								// console.log('ON CHANGE SPECIAL CASE 3 NO CALC', currentValue);
							} else {
								if(parseFloat(currentValue) > props.balance &&
									parseFloat(currentValue) < props.maxBetAmount &&
									parseFloat(currentValue) >= props.minBetAmount) {
									if(props.balance >= props.minBetAmount) {
										props.setCurrentBetAmount(props.balance);
										storage.set('currentBetAmount', props.balance);
										// console.log('ON CHANGE SPECIAL CASE 4 NO CALC', currentValue);
									} else {
										// TEST DISABLE
										// props.setCurrentBetAmount(props.minBetAmount);
										// storage.set('currentBetAmount', props.minBetAmount);
										props.setCurrentBetAmount(parseFloat(currentValue));
										storage.set('currentBetAmount', parseFloat(currentValue));
										// console.log('ON CHANGE SPECIAL CASE 5 NO CALC', currentValue);
									}
								} else if(parseFloat(currentValue) >= props.maxBetAmount) {
									if(props.balance >= parseFloat(props.maxBetAmount)) {
										props.setCurrentBetAmount(props.maxBetAmount);
										storage.set('currentBetAmount', props.maxBetAmount);
										// console.log('ON CHANGE SPECIAL CASE 6 NO CALC', currentValue);
									} else {
										props.setCurrentBetAmount(props.balance);
										storage.set('currentBetAmount', props.balance);
										// console.log('ON CHANGE SPECIAL CASE 7 NO CALC', currentValue);
									}
								} else if(parseFloat(currentValue) < props.minBetAmount) {
									// TEST DISABLE
									// props.setCurrentBetAmount(props.minBetAmount);
									// storage.set('currentBetAmount', props.minBetAmount);
									// console.log('ON CHANGE SPECIAL CASE 8 NO CALC', currentValue);
									props.setCurrentBetAmount(currentValue);
									storage.set('currentBetAmount', currentValue);
								}
							}
						}}
						onKeyDown={(e) => {
							if(sessionStorage.getItem('amountFormat') === '###,###' && e.key === '.') {
								e.preventDefault();
							}
						}}
						// OG-649
						disabled={props.betStatus === 1 || sessionStorage.getItem('fixedBet') != null ? true : false}
					/>
					<button
						className="choose-amount-input-button plus"
						onClick={incrementCount}
						disabled={props.betStatus === 1 ? true : false}
					>
						+
					</button>
				</div>
				<div className="choose-amount-button-wrapper">
					{props.prefinedBets.map((item, index) => {
						return (
							<div key={index} className="choose-amount-button-holder">
								<ChooseAmountButton
									onClick={() => {
										sessionStorage.setItem('preventDC', null);
										storage.set('dontCheckBackToMin', true);
										props.setCurrentBetAmount(props.prefinedBets[index])
										storage.set('currentBetAmount', props.prefinedBets[index]);
										soundEffect.play('increase_amount_BTN');
									}}
									label={props.prefinedBets[index]}
									currency={props.currencyCode}
									disabled={props.betStatus === 1 ? true : false}
								/>
							</div>
						);
					})}
				</div>
				{useMemo(
					() =>
						props.autoPlay ? (
							<div className="place-bet-wrapper">
								<div className="place-bet-button-holder place-bet-button-holder-full">
									<SecondaryButton
										small
										onClick={() => {
											props.setAutoPlayRounds(0);
											storage.set('autoPlayCurrentRound', 0);
											storage.remove('autoplay')
											props.setAutoPlay(false);
											soundEffect.play('decrease_amount_BTN');
										}}
										label={label.t("cancel_autoplay")}
									/>
								</div>
							</div>
						) : (
							<div className="place-bet-wrapper">
								<div id="autoPlayBtnId" style={{display: (storage.get('fastBallsAutoPlay') === false ? "none" : "block")}} className="place-bet-button-holder place-bet-button-holder-short">
									<SecondaryButton
										small
										onClick={() => {
											if(props.ticketNumbers.length >= 6 || storage.get('lastPlayedTicked') !== null) {
												props.setAutoPlayShow(true);
												soundEffect.play('set_auto_click_sound');
											}
										}}
										style={(urlParams.get("language") === 'bg' || urlParams.get("lang") === 'bg' ? {lineHeight: '1'} : null)}
										label={label.t("autoplay")}
										disabled={props.betStatus === 1 ||
											(props.ticketNumbers.length < 6) ||
											props.balance < props.minBetAmount
											? true : false}
									/>
								</div>
								<div id="playBtnId" className="place-bet-button-holder place-bet-button-holder-long">
									{(showCancelBtn() === true ?
										<PrimaryButton
											onClick={() => {
												// cancel bet
												setSpamFilterPlaceBet(true);
												setTimeout(() => {setSpamFilterPlaceBet(false)}, 1500);
												props.net.cancelBet(JSON.parse(storage.get('betAckTicketData')).ticketId);
											}}
											label={label.t("cancel")}
											disabled={
												JSON.parse(storage.get('betAckTicketData')).ticketType === 2 ? true : false
											}
										/> :
										<PrimaryButton
											onClick={placeBet}
											label={label.t("place_bet")}
											disabled={
												props.betStatus === 1 || props.ticketNumbers.length < 6 ||
													(props.balance < props.minBetAmount && !(props.promoBalance >= props.minBetAmount)) ||
													spamFilterPlaceBet === true
													? true : false
											}
										/>
									)}
								</div>
							</div>
						),
					// eslint-disable-next-line react-hooks/exhaustive-deps
					[placeBet, props]
				)}
			</div>
		</div>
	);
}
