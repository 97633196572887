export default function PrimaryButton(props) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={"primary-button " + (props.small ? "small" : "")}
      style={(props.style === null || typeof props.style === 'undefined' ? null : props.style)}
    >
      {props.label}
    </button>
  );
}
