import ResultsBoxMyBetsOld from "../Results/ResultsBoxMyBetsOld";
import FadeIn from "react-fade-in";
import {useMemo} from "react";

function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;    
}

export default function MyBetsOld(props) {
  return useMemo(() => {
    return (<div className="results-tab">
      <FadeIn className="my-results-reversed">
        {props.myBetsItems.map((result, index) => {
          var t = convertUTCDateToLocalDate(new Date(result.created_time.replace(' ', 'T')))
          // console.log('convert  t :  ',  t.toLocaleDateString())
          var nl = t.toLocaleTimeString()
          nl = nl.split(' ')[0]
          var N = t.toISOString()
          var date = N.split("T")[0];
          date = date.replace('-', '.')
          date = date.replace('-', '.')
          var dateY = date.split(".")[0]
          var dateM = date.split(".")[1]
          var dateD = date.split(".")[2]
          var constructMe = dateD + '.' + dateM + '.' + dateY;
          return (<ResultsBoxMyBetsOld
            withoutHeader
            indexArray={result.round_numbers}
            key={index}
            balls={result.numbers}
            date={constructMe}
            time={nl}
            roundNumber={result.round_id}
            stake={result.bet}
            win={result.win}
            win_eur={result.win_eur}
            currency={result.currency_code}
            bonusBals={result.bonusBals}
            baseStake={result.bet_eur}
            userCurrencyCode={props.userCurrencyCode}
          />)
        })}
      </FadeIn>
    </div>)
  }
    , [props.myBetsItems, props.userCurrencyCode])
}
