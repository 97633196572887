import * as React from "react";

const ResultsIconSvg = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 21H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a2 2 0 1 0 4 0h7a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-7a2 2 0 0 0-4 0Zm-1.465-2A3.998 3.998 0 0 1 12 17c1.48 0 2.773.804 3.465 2H20V5h-4.535a3.999 3.999 0 0 1-5.466 1.465A3.998 3.998 0 0 1 8.535 5H4v14h4.535ZM6 8h2v8H6V8Zm10 0h2v8h-2V8Z"
      fill="#fff"
      opacity={0.5}
    />
  </svg>
);

export default ResultsIconSvg;
