import {useMemo} from "react";
import {Tabs, Tab} from "react-bootstrap";
import AllBets from "../components/ResultsTabs/AllBets";
import BetRounds from "../components/Results/BetRounds";
import MyBets from "../components/ResultsTabs/MyBets";
import MyBetsOld from "../components/ResultsTabs/MyBetsOld";
import MyBounsingHistory from "../components/ResultsTabs/MyBonusingHistory";
import PreviousHand from "../components/ResultsTabs/PreviousHand";
import label from "../services/Translation/lang";
import TopWins from "../components/Results/TopWins";
import TopOdds from "../components/Results/TopOdds";
import {isLongLang} from "../services/UrlParamsLoader/UrlParamsLoader";

export default function Results(props) {

  const getTitle = () => {
    return (<div className="d-flex align-items-center justify-content-center">
      {label.t("all_bets")}
      <div className="chat-badge blue"> { props.allBetsItems.length } </div>
    </div>);
  };

  return (
    <div className="results" style={{display: props?.tab?.visible}}>
      <Tabs
        defaultActiveKey="allBets"
        id="uncontrolled-tab-results"
        className={"results-tabs " + (isLongLang() === true ? ' results-tab-long' : '')}
      >
        <Tab mountOnEnter eventKey="allBets" title={label.t("all_bets")}>

          <Tabs
            defaultActiveKey="myBetsAll"
            id="uncontrolled-tab-all-bets-tab"
            className="results-tabs results-sub-tabs"
          >
            <Tab title={getTitle()}
                 eventKey="myBetsAll">
              {useMemo(
                () => (
                  <AllBets
                    net={props.net}
                    userCurrencyCode={props.userCurrencyCode}
                    gameStatus={props.gameStatus}
                    allBetsItems={props.allBetsItems}
                    roundID={props.roundID}
                    indexArray={props.indexArray}
                    indexArrayQuota={props.indexArrayQuota}
                  />
                ),
                [props.net, props.userCurrencyCode, props.gameStatus, props.allBetsItems, props.roundID, props.indexArray, props.indexArrayQuota]
              )}
            </Tab>
            <Tab title={label.t('previous_hand')} eventKey="previousHand">
              {useMemo(
                () => (
                  <PreviousHand
                    userCurrencyCode={props.userCurrencyCode}
                    gameStatus={props.gameStatus}
                    allBetsItems={props.allBetsPreviousHand}
                    setLocalRecordsPrevius={props.setLocalRecordsPrevius}
                    localRecordsPrevius={props.localRecordsPrevius}
                    roundID={props.roundID}
                    indexArray={props.indexArray}
                    indexArrayQuota={props.indexArrayQuota}
                  />
                ),
                [
                  props.userCurrencyCode,
                  props.allBetsPreviousHand,
                  props.gameStatus,
                  props.indexArray,
                  props.indexArrayQuota,
                  props.roundID,
                ]
              )}
            </Tab>
          </Tabs>
        </Tab>

        <Tab eventKey="myBets" className="results-sub-tabs-holder" title={label.t("my_bets")}>
          <Tabs
            defaultActiveKey="myBetsAll"
            id="uncontrolled-tab-my-bets-tab"
            className="results-tabs results-sub-tabs"
          >
            <Tab title={label.t("my_bets")} eventKey="myBetsAll">
              {useMemo(
                () => (
                  <MyBets
                    userCurrencyCode={props.userCurrencyCode}
                    myBetsItems={props.myBetsItems}
                    indexArray={props.indexArray}
                    indexArrayQuota={props.indexArrayQuota}
                    activeTicketEmitedWin={props.activeTicketEmitedWin}
                  />
                ),
                [props.activeTicketEmitedWin, props.indexArray, props.indexArrayQuota, props.myBetsItems, props.userCurrencyCode]
              )}
              {useMemo(() => {
                return (
                  <MyBetsOld
                    userCurrencyCode={props.userCurrencyCode}
                    myBetsItems={props.myBetsItemsFromServer}
                  />
                );
              }, [props.myBetsItemsFromServer, props.userCurrencyCode])}
            </Tab>
            <Tab title={label.t('bonus_history')} eventKey="myBounsingHistory">
              <MyBounsingHistory userCurrencyCode={props.userCurrencyCode} pBonusHistory={props.pBonusHistory} />
            </Tab>
          </Tabs>
        </Tab>

        <Tab mountOnEnter eventKey="betRounds" title={label.t("bet_rounds")}>

          <Tabs
            defaultActiveKey="topWins"
            id="uncontrolled-tab-betrounds"
            className="results-tabs results-sub-tabs"
            onSelect={(e)=> {
              props.net.requestTopWinsWeekly();
            }}
          >
            <Tab title={label.t('top_wins')} eventKey="topWins">
              {useMemo(
                () => (
                  <TopWins net={props.net} userCurrencyCode={props.userCurrencyCode} topWinsItems={props.topWinsItems} />
                ),
                [props.net, props.topWinsItems, props.userCurrencyCode]
              )}
            </Tab>

            <Tab title={label.t('top_odds')} eventKey="topOdds">
              {useMemo(
                () => (
                  <TopOdds net={props.net} userCurrencyCode={props.userCurrencyCode} topWinsItems={props.topOddsItems} />
                ),
                [props.net, props.topOddsItems, props.userCurrencyCode]
              )}
            </Tab>

            <Tab title={label.t('bet_rounds')} eventKey="betRounds-BetRounds">
              {useMemo(
                () => (
                  <BetRounds historyData={props.historyData} />
                ),
                [props.historyData]
              )}
            </Tab>

          </Tabs>
        </Tab>
      </Tabs>
    </div>
  );
}
