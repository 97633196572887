import {useState, useEffect} from "react";
import oddHolderDefault from "../../model/odd-holder";
import CurrentRoundBall from "./CurrentRoundBall";

export default function CurrentRoundResults(props) {
  const [isEventAdded, setIsEventAdded] = useState(0);
  const [oddHolder, setOddHolder] = useState(oddHolderDefault);

  useEffect(() => {
    if(props.gameStatus === 0) {
      setOddHolder((prevState) => {
        let local = [...prevState];
        local.forEach((item, index) => {
          local[index].number = undefined;
        })
        return [...local];
      })
    }
  }, [props.gameStatus]);

  useEffect(() => {
    function catchOnCurrentDrawnNumber(data) {
      setOddHolder((prevState) => {
        let local = [...prevState];
        local[data.detail.numberIndex].number = parseInt(data.detail.number, 10);
        return [...local];
      })
    }
    if(isEventAdded === 0) {
      setIsEventAdded(1);
      window.addEventListener('onCurrentDrawnNumber', catchOnCurrentDrawnNumber);
    }
  }, [isEventAdded, oddHolder]);

  if(props?.hidden === true) return null;

  return (
    <div className="current-round-results-holder">
      {oddHolder.map((ball, index) => {
        return (
          <CurrentRoundBall
            bonus={props.bonus}
            position={ball.position}
            label={(ball.number === null ? undefined : ball.number)}
            odd={ball.odd}
            key={index}
          />
        );
      })}
    </div>
  );
}
