import storage from "../Storage/Storage";
export const urlParams = new URLSearchParams(window.location.search);

export function getGamePlayParams() {
  const JPINTERVAL = urlParams.get("JPINTERVAL") || 200;

  return {
    JPINTERVAL: JPINTERVAL
  };
}

export function isLongLang() {
  var longLangs = ['nl'];
  var current = urlParams.get("language") || urlParams.get("lang");
  var FLAG = false;
  longLangs.forEach((lang) => {
    if(lang === current) {FLAG = true}
  })
  return FLAG;
}

export async function getInitUrlParams() {

  const currency = urlParams.get("currency") || "EUR";
  storage.set('userCurrency', currency);
  const min = Math.ceil(1);
  const max = Math.floor(99999);
  const randomPart = Math.floor(Math.random() * (max - min + 1)) + min;
  const uid = urlParams.get("uid") || String(new Date().valueOf()) + 'rnd' + randomPart;
  const companyId = urlParams.get("companyId");
  storage.set('companyId', companyId);
  const token = urlParams.get("token");
  let language =
    urlParams.get("language") || urlParams.get("lang")
      ? urlParams.get("language") || urlParams.get("lang")
      : "en";

      if (sessionStorage.getItem('LANG_URL_PARAM_IS_ILEGAL') === "true" || sessionStorage.getItem('LANG_URL_PARAM_IS_ILEGAL') == true) {
        language = 'en';
      }
  // try {
  //   // Exception logic
  //   if(language === 'rs') {language = 'sr';}
  //   var url = "/multiLang/" + language + ".json";
  //   const response = await fetch(url, {
  //     headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
  //   });
  //   if(!response.ok) {
  //     throw new Error('Request failed');
  //   }
  // } catch(error) {
  //   language = 'en';
  // }

  const backUrl = urlParams.get("backUrl");
  const chatRoom = urlParams.get("chatRoom");
  const countryCode = urlParams.get("countryCode");
  const autoBet = urlParams.get("autobet");
  const autoCashout = urlParams.get("autocashout");
  const hideHeader = urlParams.get("hideHeader");
  const version = urlParams.get("version");

  // Ako ima launchId onda na login ne salje nista drugo sem launchId...
  const launchId = urlParams.get("launchId");
  let platformParams;
  let userDefinedUsername = localStorage.getItem('username') || '';

  // force fix / hot fix
  if(userDefinedUsername !== '') {
    let handler = userDefinedUsername;
    handler = handler.replaceAll('"', '');
    handler = handler.replaceAll('\\', '');
    handler = handler.replaceAll('//', '');
    userDefinedUsername = handler;
    localStorage.setItem('username', handler);
  }

  if(userDefinedUsername === 'empty') {
    userDefinedUsername = '';
  }

  if(launchId === null) {
    platformParams = {
      userDefinedUsername: userDefinedUsername,
      companyId: companyId || 1,
      uid: uid || 'ffff1212',
      token,
      currency,
      language,
      backUrl,
      chatRoom,
      countryCode,
      autoBet,
      autoCashout,
      hideHeader,
      version
    }
  } else {
    platformParams = {
      launchId
    }
  }

  /**
   * MODEL LOGIN EMIT
   * {"userDefinedUsername":"veryNice",
   * "companyId":"1",
   * "launchId":null,
   * "uid":"asdf4567",
   * "token":null,
   * "currency":"rsd",
   * "language":"en",
   * "chatRoom":null,
   * "countryCode":null}
   */
  return platformParams;
}