import loadingScreenBackground from "../../assets/images/loading-screen-background-low.webp";
import loadingScreenLogo from "../../assets/images/loading-screen-logo.svg";
import FadeIn from "react-fade-in";

export default function LoadingScreen(props) {
  return (
    <div className={"loading-screen"}>
      <div className="loading-screen-background">
        <img
          src={loadingScreenBackground}
          alt="loading-background"
        />
      </div>
      <FadeIn delay={200}>
        <img src={loadingScreenLogo} alt="logo" />
      </FadeIn>
      <FadeIn delay={400} className="loading-screen-text">
        <div className="loading-screen-dot-holder">
          LOADING ...
          <div className="loading-bullet-holder">
            <div className="loading-bullet"></div>
            <div className="loading-bullet"></div>
            <div className="loading-bullet"></div>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}
