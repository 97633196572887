import {useMemo, useState} from "react";
import storage from "../../services/Storage/Storage";
import label from "../../services/Translation/lang";
import PrimaryButton from "../Buttons/PrimaryButton";
import soundEffect from "../../services/Sounds/SoundFxPlayer";
import {urlParams} from "../../services/UrlParamsLoader/UrlParamsLoader";

var autoplayValues = [
  {label: "3", value: 3},
  {label: "5", value: 5},
  {label: "10", value: 10},
  {label: "∞", value: Infinity},
];

export default function AutoplayPopUp(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeValue, setActiveValue] = useState(3);
  const handleOnClick = (index, value) => {
    setActiveIndex(index);
    setActiveValue(value);
  };

  // console.log('BIG TEST maxAutoPlayGames', storage.get('maxAutoPlayGames')) 
  autoplayValues[3].label = storage.get('maxAutoPlayGames');
  autoplayValues[3].value = storage.get('maxAutoPlayGames');

  return (
    <div className="pop-up-wrapper autoplay-pop-up">
      <div
        onClick={() => props.setAutoPlayShow(false)}
        className="pop-up-background"
      ></div>
      <div className="pop-up-body">
        <p>
          {label.t("autoplay_popup_1")} <b>{label.t("autoplay_popup_2")}</b>{" "}
          {label.t("autoplay_popup_3")}
        </p>
        <div className="autoplay-pop-up-wrapper">
          {autoplayValues.map((ap, index) => {
            return (
              <div key={index} className="autoplay-pop-up-button-holder">
                <button
                  onClick={() => handleOnClick(index, ap.value)}
                  className={
                    "autoplay-pop-up-button " +
                    (activeIndex === index ? "active" : "")
                  }
                  value={ap.value}
                >
                  {ap.label}
                </button>
              </div>
            );
          })}
        </div>
        {useMemo(() => (<PrimaryButton
          onClick={() => {
            soundEffect.play('set_autoplay');
            props.setAutoPlayShow(false);
            props.setAutoPlayRounds(activeValue);
            storage.set('autoPlayCurrentRound', 1);
            props.setAutoPlay(true);
            storage.set('autoplay', true);
            storage.set('currentBetAmount', props.currentBetAmount);
            // placebet
            dispatchEvent(new CustomEvent("place-bet-autoplay",
              {
                detail: {
                  ticketNumbers: props.ticketNumbers,
                  sid: props.sid,
                  minBetAmount: props.minBetAmount,
                  currentBetAmount: props.currentBetAmount
                }
              }));
          }}
          label={label.t("set_autoplay")}
          style={ (urlParams.get("language") === 'bg' || urlParams.get("lang") === 'bg' ? {lineHeight: '0.9', fontSize: '18px'} : null)}
        />), [activeValue, props])}
        <button
          onClick={() => {
            storage.set('autoplay', false);
            props.setAutoPlayShow(false);
            props.setAutoPlay(false);
            props.setAutoPlayRounds(0);
            storage.set('autoPlayCurrentRound', 0);
          }}
          className="button-only-text"
        >
          {label.t("no_autoplay")}
        </button>
      </div>
    </div>
  );
}
