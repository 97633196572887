import * as React from "react";

const ActiveTicketSvg = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 18v-6a8 8 0 1 1 16 0v6h1v2H1v-2h1Zm2-6h2a4 4 0 0 1 4-4V6a6 6 0 0 0-6 6ZM9 0h2v3H9V0Zm8.778 2.808 1.414 1.414-2.12 2.121-1.415-1.414 2.12-2.121ZM.808 4.222l1.414-1.414 2.12 2.12L2.93 6.344.808 4.222Z"
      fill="#1070FF"
    />
  </svg>
);

export default ActiveTicketSvg;
